.FormEquipment {
    .initialSelects {
        margin-bottom: 3vw;
        .select {
            width: 14vw;
            height: 3.6vw;
            margin-right: 2vw;
            border: 1px solid #c4c4c4;
            border-radius: 5px;
            font-size: 1.5vw;
        }
        .ant-select-selector {
            height: 3.4vw;
            border-radius: 5px;
            border: 0;
        }
    }
    .ant-steps {
        width: 40vw;
        align-self: center;
        justify-self: center;
        position: relative;
        left: 17vw;
    }
    .ant-steps-item-active .ant-steps-item-icon {
        background-color: #10b759;
    }
    .errorMessage {
        color: red;
    }
    .first {
        display: flex;
        justify-content: space-between;
        margin-top: 3vw;
        margin-bottom: 3vw;
        .divSelect {
            position: relative;
            top: 1vw;
            .titleSelect {
                margin-bottom: -0.5vw;
                margin-top: -1vw;
                margin-left: 0.1vw;
                font-weight: 400;
                font-size: 1.1vw;
                font-weight: 600;
                font-family: "Noto Sans Thai", sans-serif;
            }
            .select {
                width: 14vw;
                height: 3vw;
                margin-right: 2vw;
                border: 1px solid #c4c4c4;
                border-radius: 5px;
                font-size: 1.3vw;
                margin-top: 0.75vw;
            }
            .ant-select-selector {
                height: 2.7vw;
                border-radius: 5px;
                border: 0;
            }
        }
    }
    .featuresText {
        color: #c4c4c4;
        font-size: 1.8vw;
    }
    hr {
        color: "#C4C4C4";
        margin-top: -1vw;
    }
    .featuresFirstDiv {
        margin-top: 2.75vw;
        width: 73.5vw;
        display: flex;
        justify-content: space-between;
    }
    .featuresDiv {
        margin-top: 1.5vw;
        height: 29.5vw;
        width: 73.5vw;
        justify-content: space-between;
        display: flex;
        margin-bottom: 3.5vw;
        .imageDiv {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            img {
                width: 22vw;
                height: 25vw;
            }
            .canvas {
                width: 15vw;
                height: 0vh;
            }

            input[type="file"] {
                display: none;
            }
            .custom-file-upload {
                border: 1px solid #ccc;
                cursor: pointer;
                padding-left: 0.7vw;
                padding-right: 0.7vw;
                color: #ffffff;
                background-color: #4e8e30;
                border-radius: 10px;
                font-size: 1.2vw;
                text-align: center;
                height: 3vw;
                width: 10vw;
                line-height: 1.3vw;
            }
            .imageButtons {
                display: flex;
                justify-content: space-between;
                width: 22vw;
                margin-top: 1vw;
            }
        }
        .firstInfoDiv {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 2vh;
            height: 35vw;
        }
        .secondInfoDiv {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 35vw;

            div {
                p {
                    margin-left: 0.1vw;
                    font-weight: 400;
                    font-size: 1.1vw;
                    font-weight: 600;
                    font-family: "Noto Sans Thai", sans-serif;
                    margin-bottom: 0.5vw;
                }
            }
        }
        .thirdInfoDiv {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 35vw;
            div {
                p {
                    margin-left: 0.1vw;
                    font-weight: 400;
                    font-size: 1.1vw;
                    font-weight: 600;
                    font-family: "Noto Sans Thai", sans-serif;
                    margin-bottom: 0.5vw;
                }
            }
        }
        .buttonDeleteDate {
            background-color: $orange;
            color: white;
            border: 1px solid #000;
            box-shadow: $basicShadow;
            font-size: 1vw;
            cursor: pointer;
            position: relative;
            bottom: 5vw;
            left: 13vw;
        }
    }
    .risk {
        margin-top: 3vh;
        width: 73.5vw;
        .riskTitle {
            margin-bottom: 5vh;
            font-size: 1.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .risksDiv {
            display: flex;
            margin-top: 8vh;
            justify-content: space-between;
        }
        .risksDiv1 {
            display: flex;
            justify-content: space-between;
            margin-top: 5vh;
        }
    }
    .checkBoxes {
        margin-top: 5vh;
        margin-bottom: 8vh;
        display: flex;
        justify-content: space-between;
        div {
            display: flex;
            flex-direction: column;
            p {
                font-size: 1.5vw;
                margin-bottom: -1vh;
            }
            .checkboxG {
                .checkbox {
                    margin-top: 3vh;
                    font-size: 1.5vw;
                }
            }
            .checkboxGH {
                display: flex;
                flex-direction: row;
                .checkbox {
                    margin-top: 3vh;
                    font-size: 1.5vw;
                }
            }
        }
    }
    .secondSection {
        width: 73.5vw;
        .title {
            font-size: 1.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .newComp {
            display: flex;
            margin-top: 3vh;
            justify-content: flex-flex-end;
        }
        .comp {
            display: flex;
            margin-top: 7vh;
            justify-content: space-between;
            .closeIcon {
                color: red;
            }
        }
        .firstCheckBoxes {
            margin-top: 10vh;
            margin-bottom: 8vh;
            display: flex;
            justify-content: space-between;
            div {
                display: flex;
                flex-direction: column;
                p {
                    font-size: 1.5vw;
                    margin-bottom: -1vh;
                }
                .radioG {
                    .radio {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                }
                .radioGH {
                    display: flex;
                    flex-direction: row;
                    .radio {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                }
                .checkboxGH {
                    display: flex;
                    flex-direction: row;
                    .checkbox {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                }
            }
        }
        .secondCheckBoxes {
            margin-top: 5vh;
            margin-bottom: 8vh;
            display: flex;
            justify-content: space-around;
            div {
                display: flex;
                flex-direction: column;
                p {
                    font-size: 1.5vw;
                    margin-bottom: -1vh;
                }
                .radioG {
                    .radio {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                }
                .radioGH {
                    display: flex;
                    flex-direction: row;
                    .radio {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                }
            }
        }
    }
    .third {
        .title1 {
            display: flex;
            justify-content: center;
            font-size: 1.5vw;
        }
        .thirdCheckBoxes {
            margin-top: 5vh;
            margin-bottom: 8vh;
            display: flex;
            justify-content: space-around;
            div {
                display: flex;
                flex-direction: column;
                p {
                    font-size: 1.5vw;
                    margin-bottom: -1vh;
                }
                .radioG {
                    .radio {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                }
                .radioGH {
                    display: flex;
                    flex-direction: row;
                    .radio {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                    .firstDivRadios {
                        margin-left: 1.9vw;
                        margin-top: 0.4vh;
                    }
                    .secondDivRadios {
                        margin-left: 5.9vw;
                    }
                }
            }
            .littleTexts {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 12vw;
                .littleText {
                    font-size: 1.2vw;
                }
            }
        }
        .files {
            display: flex;
            gap: 1vw;
            margin-bottom: 2vw;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-around;
            p {
                font-size: 1.1vw;
                margin-bottom: 0.2vw;
                font-family: 'Roboto',sans-serif;
                font-weight: 600;
            }
            .file {
                background: #10b759;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                border-radius: 5px;
                color: white;
                font-weight: 500;
                cursor: pointer;
                width: 32%;
            }
        }
        .asignatedAndShared {
            display: flex;
            flex-direction: row;
            justify-content: flex-flex-end;
            .select {
                width: 17.5vw;
                height: 3.6vw;
                margin-right: 2vw;
                border: 1px solid #c4c4c4;
                border-radius: 5px;
                font-size: 1.5vw;
            }
            .ant-select-selector {
                height: 3.4vw;
                border-radius: 5px;
                border: 0;
            }
        }
        textarea {
            width: 78vw;
            height: 15vh;
            margin-bottom: 5vh;
            background: rgba(189, 189, 190, 0.05);
            border: 0.5px solid rgba(0, 0, 0, 0.08);
            box-sizing: border-box;
            border-radius: 3px;
        }
    }
}

.Register {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #3e5851;
    hr {
        width: 47vw;
        margin-top: 0.4vw;
    }
    .container {
        width: 80vw;
        height: 43vw;
        display: flex;
        .enterpriseDiv {
            width: 35%;
            height: 100%;
            background: $green;
            backdrop-filter: blur(2px);
            border-radius: 35px 0px 0px 35px;
            padding: 1vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .imgDiv {
                display: flex;
                width: 90%;
                img {
                    width: 3vw;
                    height: 3vw;
                    cursor: pointer;
                }
            }
            img {
                width: 80%;
                height: 15vw;
            }
            p {
                font-family: "Noto Sans Thai", sans-serif;
                font-style: normal;
                font-weight: 800;
                font-size: 2vw;
                text-align: center;
                color: white;
                margin-top: 2vw;
            }
        }
        .formDiv {
            width: 65%;
            height: 100%;
            background-color: white;
            backdrop-filter: blur(2px);
            border-radius: 0px 35px 35px 0px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .titleSection {
                font-family: "Noto Sans Thai";
                font-style: normal;
                font-weight: 700;
                color: #10b759;
                font-size: 2vw;
            }
            .margintop {
                margin-top: 3vw;
            }
            .sections {
                display: flex;
                width: 100%;
                justify-content: space-around;
                p {
                    font-family: "Noto Sans Thai";
                    font-style: normal;
                    font-weight: 400;
                    color: #000000;
                    margin-bottom: 0.3vw;
                    text-align: left;
                    width: 20vw;
                }
                input {
                    border-radius: 5px;
                    border: 1px solid rgba(156, 149, 149, 0.5);
                    margin-bottom: 1vw;
                    width: 20vw;
                    height: 2.5vw;
                }
            }
            .section {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .selects {
                    display: flex;
                    flex-direction: column;
                    .select {
                        width: 24.5vw;
                        margin-bottom: 1vw;
                    }
                    .ant-select-selector {
                        border-radius: 10px;
                        height: 3.3vw;
                    }
                    .ant-select-selection-item {
                        line-height: 3vw;
                    }
                }
                .divInput {
                    .inputLabel {
                        height: 2vw;
                        border: 1px solid rgba(156, 149, 149, 0.5);
                        border-radius: 10px;
                    }
                    .inputLabel::placeholder {
                        font-family: "Noto Sans Thai";
                        font-style: normal;
                        font-weight: 400;
                    }
                }
            }
            .section1 {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .canvas {
                    width: 13vw;
                }
                input[type="file"] {
                    display: none;
                }
                .custom-file-upload {
                    border: 1px solid #ccc;
                    display: inline-block;
                    cursor: pointer;
                    padding: 0;
                    color: #4e8e30;
                    background-color: "#4E8E30";
                    border-radius: "10px";
                    width: 11vw;
                    text-align: center;
                    height: 3vw;
                    margin-bottom: 2vw;
                }
                .pColor {
                    font-weight: 600;
                    text-align: left;
                    width: 17vw;
                }
                .color {
                    width: 17vw;
                    height: 2vw;
                    background-color: #ffffff00;
                    padding: 0;
                    border: 0;
                    margin-top: -1vw;
                }
                .inputDiv {
                    margin-top: 2vw;
                    .divInput {
                        .inputLabel {
                            height: 2vw;
                            border: 1px solid rgba(156, 149, 149, 0.5);
                            border-radius: 10px;
                        }
                        .inputLabel::placeholder {
                            font-family: "Noto Sans Thai";
                            font-style: normal;
                            font-weight: 400;
                        }
                    }
                }
                .phoneSelector {
                    margin-top: 1vw;
                    margin-bottom: 1vw;
                    width: 20vw;
                }
            }
            .head {
                display: flex;
                width: 95%;
                .searchHeadquarter {
                    width: 40vw;
                    border: 0;
                    outline: none;
                }
                .addHeadquarter {
                    background: #10b759;
                    border-radius: 4px;
                    padding: 0.3vw;
                    padding-right: 1vw;
                    padding-left: 1vw;
                    color: #ffffff;
                    font-family: "Lato", sans-serif;
                    cursor: pointer;
                    width: 14vw;
                    height: 2.8vw;
                }
            }

            .columnsName {
                display: flex;
                align-self: flex-start;
                margin-left: 5vw;
                width: 70%;
                justify-content: space-between;
                p {
                    font-weight: 700;
                    color: #9daebe;
                    text-align: left;
                }
            }
            .columnsInputs {
                display: flex;
                flex-direction: column;
                height: 56%;
                margin-bottom: 1vw;
                overflow-y: auto;
                div {
                    display: flex;
                    justify-content: space-between;
                    width: 42vw;
                    margin-bottom: 0.5vw;
                }
                input {
                    width: 8vw;
                    border-radius: 5px;
                    border: 1px solid #000;
                }
                .closeIcon {
                    color: red;
                    cursor: pointer;
                }
            }
        }
        .foot {
            width: 80%;
            display: flex;
            justify-content: flex-end;
        }
        .column {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
        .userAdmin {
            display: flex;
            justify-content: space-between;
            width: 86%;
            p {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                color: #5b616b;
            }
            div {
                background: #10b759;
                border: 1px solid #9c9595;
                border-radius: 5px;
                font-family: "Noto Sans Thai";
                font-style: normal;
                font-weight: 700;
                color: #ffffff;
                width: 5vw;
                height: 2vw;
                display: flex;
                justify-content: space-around;
                align-items: center;
                cursor: pointer;
            }
        }
        .adminHeaders {
            display: flex;
            align-self: flex-start;
            margin-left: 5vw;
            width: 70%;
            justify-content: space-between;
            p {
                font-family: "Lato";
                font-style: normal;
                font-weight: 700;
                color: #9daebe;
            }
        }
        .adminsCreated {
            height: 24%;
            width: 87%;
            overflow-y: auto;
        }
        .admin {
            display: flex;
            justify-content: space-around;
            align-items: center;
            background: #ffffff;
            border: 0.5px solid #e5e8ec;
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            height: 4.2vw;
            .contact {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .email {
                    font-family: "Lato";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.8vw;
                    text-align: center;
                    color: #9daebe;
                }
                p {
                    margin-bottom: 0;
                }
            }
            .buttons {
                display: flex;
                justify-content: space-between;
                width: 3.5vw;
                .icon {
                    color: rgba(0, 0, 0, 0.3);
                    font-size: 1.5vw;
                }
            }
        }
        .pCreate {
            width: 85%;
            font-size: 1vw;
            font-weight: bold;
        }
        .divCreate {
            width: 85%;

            .create {
                width: 30vw;
            }
            .css-1s2u09g-control .createMulti {
                height: 10vw;
            }
            .css-g1d714-ValueContainer{
                height: 5vw;
                overflow-y: auto;
            }
            
            .delete {
                background-color: $orange;
                width: 6vw;
                height: 2vw;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: white;
                box-shadow: $basicShadow;
                font-size: 1vw;
                margin-top: 0.4vw;
                cursor: pointer;
            }
            .css-1bioabq {
                width: 44vw;
                height: 17vw;
                overflow-x: auto;
            }
            .treeNode {
                padding: 3px 8px;
                font-size: 0.65vw;
                border-radius: 8px;
                display: inline-block;
                border: 1px solid rgb(255, 115, 0);
            }
        }
        .Cards {
            display: flex;
            justify-content: space-around;
            width: 70%;
            .icon {
                color: #4e8e30;
                font-size: 2vw;
            }

            .Card {
                background: #ffffff;
                box-shadow: 6px 3px 12px rgba(0, 0, 0, 0.03);
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                border-radius: 10px;
                width: 12vw;
                height: 10vw;
                cursor: pointer;
                div {
                    border-bottom: 1px solid #e3dfdf;
                    height: 7.5vw;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    p {
                        color: #4e8e30;
                        font-family: "Montserrat";
                        font-weight: 500;
                        font-size: 1vw;
                        text-align: center;
                        position: relative;
                        top: 1vw;
                    }
                }
            }
        }
        .columnsNameUser {
            display: flex;
            align-self: flex-start;
            margin-left: 2.2vw;
            width: 85.5%;
            justify-content: space-between;
            p {
                font-weight: 700;
                color: #9daebe;
                text-align: left;
                font-size: 1vw;
            }
            #p1 {
                margin-right: 4vw;
                margin-left: 1.5vw;
            }
            #p3 {
                margin-left: 2vw;
                margin-right: -3vw;
            }
            #p4 {
                margin-right: -1vw;
            }
        }
        .user {
            display: flex;
            justify-content: space-around;
            align-items: center;
            background: #ffffff;
            border: 0.5px solid #e5e8ec;
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            height: 4.2vw;
            width: 95%;
            p {
                font-size: 0.8vw;
            }
            .contact {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .email {
                    font-family: "Lato";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.8vw;
                    text-align: center;
                    color: #9daebe;
                }
                p {
                    margin-bottom: 0;
                }
            }
            .buttons {
                display: flex;
                justify-content: space-between;
                width: 2.5vw;
                .icon {
                    color: rgb(0, 0, 0);
                    font-size: 1vw;
                }
            }
        }
        .continue {
            background: #10b759;
            border: 1px solid #9c9595;
            border-radius: 10px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Noto Sans Thai", sans-serif;
            font-style: normal;
            font-weight: 700;
            cursor: pointer;
            width: 10vw;
            height: 2vw;
            margin-top: 0.3vw;
        }
    }
}
.ModalHeadquarter .ant-modal-content {
    border-radius: 10px;
    .header {
        border-radius: 10px 10px 0px 0px;
        background-color: $green;
        height: 4vw;
        p {
            font-family: "Montserrat";
            font-weight: 700;
            color: #ffffff;
            position: relative;
            top: 0.7vw;
            left: 2vw;
            font-size: 1.25vw;
        }
    }
    .body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 4vw;
        padding-top: 0;
        margin-top: 2vw;
        .label {
            color: #5b616b;
            font-family: "Lato";
        }
        input {
            height: 2vw;
            width: 23vw;
            border-radius: 10px;
            border: 1px solid #ebedf0;
        }
    }
    .bodyUser {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 4vw;
        padding-top: 0;
        margin-top: 2vw;
        .label {
            color: #000000;
            font-family: "Lato";
            font-weight: 700;
            font-size: 1vw;
        }
        input {
            height: 2vw;
            width: 15vw;
            border-radius: 10px;
            border: 1px solid #c4c4c4;
            font-size: 1.1vw;
            padding-left: 0.5vw;
        }
        .ant-select-selector {
            font-size: 1.1vw;
            line-height: 0;
            width: 15vw;
            height: 2vw;
            margin-top: 2vw;
            border-radius: 10px;
            border: 1px solid #c4c4c4;
        }
        .ant-select-selection-item {
            line-height: 2vw;
        }
        em {
            position: relative;
            top: -0.4vw;
        }
        .ant-select-arrow {
            margin-top: 0.7vw;
        }
        .radio {
            position: relative;
            top: -1vw;
        }
        .ant-radio-inner {
            width: 1vw;
            height: 1vw;
        }
        .ant-picker {
            border: 0;
            position: relative;
            top: -1vw;
            left: -1vw;
            margin-right: -3vw;
        }
        span {
            font-size: 1vw;
        }
        .canvas {
            width: 15vw;
            height: 5vw;
        }
        input[type="file"] {
            display: none;
        }
        .custom-file-upload {
            border: 1px solid #ccc;
            display: inline-block;
            cursor: pointer;
            padding: 0;
            color: #4e8e30;
            font-size: 1vw;
            background-color: "#4E8E30";
            border-radius: "10px";
            width: 9vw;
            text-align: center;
            height: 2vw;
            margin-bottom: 0vw;
        }
    }

    .footer {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: -2vw;
        p {
            margin-right: 2vw;
            color: #4e8e30;
            font-family: "Lato";
            font-weight: 700;
            font-size: 1.4vw;
            cursor: pointer;
        }
        p:active {
            color: #407527;
        }
    }
}
.ModalHeadquarter .ant-modal-body {
    padding: 0;
}
.ant-modal-close-x {
    color: white;
}

.FormEquipmentIndustrial {
    margin-bottom: 3vw;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 1.5vw;
    .initialSelects {
        margin-bottom: 3vw;
        .select {
            width: 14vw;
            height: 2.7vw;
            margin-right: 1vw;
            border: 1px solid #c4c4c4;
            border-radius: 5px;
            font-size: 1.1vw;
        }
        .ant-select-selector {
            height: 2.6vw;
            border-radius: 5px;
            border: 0;
        }
    }
    .errorMessage {
        color: red;
        font-size: 1vw;
    }
    .selectEquips {
        width: 18vw;
        height: 2.7vw;
        margin-right: 2vw;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        font-size: 1.1vw;
    }
    .selectEquips .ant-select-selector {
        height: 2.2vw;
        border-radius: 5px;
        border: 0;
    }
    .titleSection {
        border-bottom: 1px solid #e3dfdf;
        font-family: Montserrat;
        font-size: 1vw;
        font-weight: 600;
        /*         line-height: 12px;
 */
        letter-spacing: 0em;
        text-align: left;
        color: #868686;
        margin-top: 2vw;
        padding-bottom: 0.3vw;
    }
    .label {
        color: #000000a6;
        font-family: Lato;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        font-size: 1vw;
        margin-bottom: 0.7vw;
    }

    .divDate {
        position: relative;
        top: -0.5vw;
        margin-top: 0.7vw;
        .label {
            position: relative;
            top: -0.1vw;
        }
    }
    .radioG {
        display: flex;
        margin-left: 1vw;
        width: 100%;
        justify-content: space-between;
        .radio {
            font-size: 1vw;
            color: #000000a6;
            font-weight: 600;
        }
    }
    .radioGManuals {
        display: flex;
        margin-left: 1vw;
        width: 100%;
        justify-content: space-between;
        .radio {
            font-size: 1vw;
            color: #000000a6;
            font-weight: 600;
        }
    }
    .radioGBlueprints {
        display: flex;
        margin-left: 1vw;
        width: 100%;
        justify-content: space-between;
        .radio {
            font-size: 1vw;
            color: #000000a6;
            font-weight: 600;
        }
    }
    .imageDiv {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        img {
            width: 15vw;
            height: 25vw;
        }
        .canvas {
            width: 100%;
            height: 18.5vw;
            border-width: 0px, 0px, 0px, 0px;
            border-style: solid;
            border-color: #e3e3e380;
            box-shadow: 0px 4px 4px 0px #00000040;
        }

        input[type="file"] {
            display: none;
        }
        .custom-file-upload {
            border: 1px solid #ccc;
            display: inline-block;
            cursor: pointer;
            padding-left: 0.7vw;
            padding-right: 0.7vw;
            color: #ffffff;
            background-color: #4e8e30;
            border-radius: 10px;
            font-size: 1vw;
            text-align: center;
            height: 3.5vw;
            width: 6vw;
        }
        .imageButtons {
            display: flex;
            justify-content: space-between;
            width: 15vw;
            margin-top: 1vw;
        }
    }
    .save {
        border-radius: 3px;
        border: 1px solid #fff;
        background: #017c5b;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #fff;
        font-size: 1vw;
        font-family: Overpass;
        font-weight: 600;
        line-height: 21px;
        padding: 0.3vw 1.8vw;
        align-self: center;
        justify-self: center;
        cursor: pointer;
        margin-bottom: 2vw;
        margin-top: 1.3vw;
        transition:
            box-shadow 2s ease,
            background 0.4s ease;
    }
    .save:active {
        background: #024533;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    }
}

.sider {
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.8vw;
    padding-top: 1vw;
    box-shadow: 0px 8px 10px 0px #b7c0ce33;
    margin-top: -10px;
    z-index: 1;
    .enterprise {
        display: flex;
        .images {
            display: flex;
            flex-direction: column;
            .imgContorno {
                width: 4vw;
            }
            .imgLogo {
                position: relative;
                bottom: 3vw;
                left: 0.3vw;
                width: 3.5vw;
            }
        }
        .texts {
            width: 10vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;
            p {
                font-family: "Overpass";
                font-weight: 600;
            }
            .name {
                color: #10b759;
                font-size: 1vw;
                width: 10vw;
                margin-bottom: 0vw;
            }
            .nit {
                color: #fdb600;
                font-size: 1vw;
            }
        }
    }

    .siderDiv {
        position: sticky;
        top: 20px;
        .inicioText {
            font-size: 1.4vw;
            font-family: "Overpass";
            color: #686868;
        }
        .boton,
        .boton1 {
            width: 14.5vw;
            height: 2.7vw;
            font-size: 1.2vw;
            font-family: Overpass;
            font-style: normal;
            font-weight: 600;
            padding-bottom: 0.5vw;
            border: 1px solid #ffffff;
            box-shadow: 0px 5px 4px 0px #00000040;
            margin-bottom: 1vw;
            border-radius: 5px;
            transition: background 0.4s ease;

            &:active {
                background-color: #28622e;
            }
        }

        .boton {
            background-color: #3e5851;
        }

        .boton1 {
            background-color: #fdb600;
            box-shadow: 0px 4px 4px 0px #00000040 inset;
        }
        .imgModule {
            width: 1.5vw;
            margin-right: 0.4vw;
        }
    }
}

.DriversDiv {
  padding: 1vw;
  padding-left: 2vw;
  width: 81.5vw;
  height: 38vw;
  @font-face {
    font-family: "Arimo-Bold";
    src: url("../../../assets/fonts/Arimo-Bold.ttf") format("truetype");
  }
  .bigDiv {
    display: flex;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 100%;
    min-height: 100%;
    gap: 1vw;
    justify-content: center;
    align-items: flex-start;
    border-radius: 4px;
    margin-bottom: 3vw;
    .optionsDiv {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      width: 20vw;
      height: 35vw;
      padding: 1vw;
      gap: 0.5vw;
      box-shadow: 0px 4px 4px 0px #00000040;
      border-radius: 3px;
      margin-bottom: 0.5vw;
      margin-left: 0.8vw;
      margin-top: 0.8vw;
      .optionCard {
        width: 95%;
        height: 40%;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.5;
        cursor: pointer;
        transition: opacity 0.5s ease;
        border-radius: 5px;
        box-shadow: $basicShadow;
        &:active {
          opacity: 0.75;
        }
        .textCard {
          font-family: "Overpass";
          font-size: 1.4vw;
          color: #fff;
          text-align: center;
          font-weight: 700;
          position: relative;
          top: 70%;
        }
      }
      .optionCard.selected {
        opacity: 1;
        box-shadow: 0px 0px 0px 0px;
      }
    }
    .contentDiv {
      width: 60vw;
      min-height: 100%;
      padding-right: 1vw;
      .filtersDiv {
        display: flex;
        gap: 0.8vw;
        flex-wrap: wrap;
        .label {
          font-size: 0.95vw;
          color: #6a6a6a;
          font-weight: 700;
          font-family: "Lato", sans-serif;
        }
        .select {
          width: 13vw;
          height: 2vw;
          border: 1px solid #c4c4c4;
          border-radius: 5px;
          font-size: 1.1vw;
        }
        .ant-select-selector {
          height: 1.85vw;
          border-radius: 5px;
          border: 0;
        }
      }
      .buttonSave {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #fff;
        background: #017c5b;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #fff;
        font-size: 0.8vw;
        font-family: Overpass;
        font-weight: 600;
        line-height: 21px;
        text-align: center;
        padding: 0.3vw 1.8vw;
        align-self: center;
        justify-self: center;
        cursor: pointer;
        width: 6vw;
        height: 1.8vw;
        gap: 0.4vw;
        margin-bottom: 1vw;
        transition:
          box-shadow 2s ease,
          background 0.4s ease;
      }
      .buttonSave:active {
        background: #024533;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
      }
      .iconButton {
        font-size: 1vw;
      }
      .title {
        color: #868686;
        border-bottom: 1px solid #e3dfdf;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 0.9vw;
      }
      .columnsName {
        display: flex;
        justify-content: space-between;
        width: 100%;
        p {
          color: #6a6a6a;
          font-size: 0.85vw;
          font-size: "Lato", sans-serif;
          font-weight: 700;
        }
      }
      .showingText {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        margin-top: 0.5vw;
      }
      .divButtonsPages {
        display: flex;
        justify-content: center;
        margin-top: 0.5vw;
        width: 100%;
        .sidesButtons {
          background-color: #f7f7f7;
          border: 2px solid #e9e9e9;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 1vw;
          padding-left: 1vw;
          cursor: pointer;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          width: 7vw;
          height: 3vw;
        }
        .RadioButtonGroup {
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 0;
          border-left: 0;
        }
        .radiobutton {
          width: 3vw;
          height: 3vw;
          color: #000;
          font-size: 1.2vw;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f7f7f7;
          border: 2px solid #e9e9e9;
        }
        .radiobutton:hover {
          color: #337ab7;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          cursor: pointer;
        }
        .radiobuttonSelected {
          width: 3vw;
          height: 3vw;
          color: rgb(255, 255, 255);
          font-size: 1.2vw;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #337ab7;
          border: 2px solid #e9e9e9;
        }
        .radiobuttonSelected:hover {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }
}

.private-header-layout {
    display: flex;
    justify-content: space-between;
    background-color: $green !important;
    padding-left: 0.7vw;
    padding-right: 5vw;
    margin-bottom: 1.2vh;
    box-shadow: 3px 0px 10px 0px #b7c0ce33;
    height: 5vw;
    z-index: 2;
    .logohome {
        display: flex;
        img {
            align-self: center;
            width: 3vw;
            height: 3vw;
        }
        .texts {
            display: flex;
            justify-content: space-between;
            margin-left: 1vw;
            /* width: 20vw; */
            .zeus {
                background-image: linear-gradient(0deg, #ffffff, #dee0ee);
                background-size: 100%;
                background-repeat: repeat;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
                font-weight: bold;
                font-size: 1.1vw;
                text-align: center;
                margin-bottom: 0;
                margin-bottom: -0.25em;
                margin-right: 0.3vw;
                font-style: normal;
                font-weight: 800;
                line-height: 4.9vw;
            }
            .helpdesk {
                background-image: linear-gradient(0deg, #ffe600, #ffe81c);
                background-size: 100%;
                background-repeat: repeat;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
                font-weight: bold;
                font-size: 1.1vw;
                text-align: center;
                margin-bottom: 0;
                font-style: normal;
                font-weight: 800;
                line-height: 4.9vw;
            }
        }
        .inputDiv {
            margin-left: 2vw;
        }
    }
    .logoheadDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        .logohead {
            width: 4.3vw;
        }
        .select {
            width: 2.2vw;
            position: relative;
            bottom: 1.2vw;
            /* background-color: white; */
        }
    }
    .rightPanel {
        display: flex;
        align-items: center;
        justify-content: center;
        .MuiSelect-icon {
            font-size: 3vw;
        }
        .notiIcon {
            font-size: 2.3vw;
            align-self: center;
            justify-self: center;
        }
        .alert {
            cursor: pointer;
        }
        .noAlert {
            filter: grayscale(1);
        }
        .avatar{
            width: 4vw;
            height: 4vw;
            margin-left: 1vw;
        }
    }
}

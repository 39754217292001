.CalibrationCertificate {
    border: 1px solid #10b759;
    border-radius: 5px;
    box-shadow: $basicShadow;
    padding: 0.6vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    width: 79vw;
    height: 5vw;
    .equipmentImage {
        width: 5vw;
    }
    .dataDiv {
        width: 68.5vw;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;
        div {
            width: 5vw;
        }
        .pFont {
            font-weight: bold;
            font-size: 1.3vw;
            text-align: center;
            line-height: 0;
        }
        .pFontData {
            font-weight: 400;
            font-size: 1vw;
            text-align: center;
            font-family: "Lato";
            color: black;
            line-height: 1vw;
        }
        .name {
            font-size: 0.8vw;
        }
        .status {
            background: #e27c0e;
            border: 1px solid #ffffff;
            box-shadow: $basicShadow;
            border-radius: 5px;
            width: 7.2vw;
            height: 2.2vw;
            display: flex;
            justify-content: center;
            align-items: center;
            .pFontStatus {
                font-family: "Overpass";
                font-style: normal;
                font-weight: 600;
                color: white;
                line-height: 0;
                margin-bottom: -0.4vw;
                font-size: 0.8vw;
            }
        }
    }
    .deleteDiv {
        height: 5vw;
        .deleteIcon {
            font-size: 1.1vw;
            color: red;
            cursor: pointer;
        }
    }
}
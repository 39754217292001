.FormAssignDriver {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 40vw;
    overflow-y: auto; */
    .row {
        display: flex;
        width: 100%;
        gap: 1vw;
        .select {
            margin-top: 0.8vw;
            width: 13vw;
            height: 2vw;
            border: 1px solid #c4c4c4;
            border-radius: 5px;
            font-size: 1.1vw;
        }
        .ant-select-selector {
            height: 1.9vw;
            border-radius: 5px;
            border: 0;
        }
        .inputPhoto {
            border: 1px solid #c4c4c4;
            border-radius: 5px;
            width: 13vw;
            height: 2vw;
        }
        .inputPhotoEdit {
            border: 1px solid #c4c4c4;
            border-radius: 5px;
            width: 13vw;
            height: 7vw;
        }
    }
    .errorMessage {
        color: red;
        font-size: 0.7vw;
    }
    .selectEquips {
        width: 18vw;
        height: 2.7vw;
        margin-right: 2vw;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        font-size: 1.1vw;
    }
    .selectEquips .ant-select-selector {
        height: 2.6vw;
        border-radius: 5px;
        border: 0;
    }
    .titleSection {
        border-bottom: 1px solid #e3dfdf;
        font-family: Montserrat;
        font-size: 1vw;
        font-weight: 600;
        /*         line-height: 12px;
 */
        letter-spacing: 0em;
        text-align: left;
        color: #868686;
        margin-top: 2vw;
        padding-bottom: 0.3vw;
    }
    .label {
        color: #000000a6;
        font-family: Lato;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        font-size: 0.9vw;
        width: 13vw;
    }

    .divDate {
        .label {
        }
    }
    .buttonSave {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #fff;
        background: #017c5b;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #fff;
        font-size: 1vw;
        font-family: Overpass;
        font-weight: 600;
        line-height: 21px;
        text-align: center;
        padding: 0.3vw 1.8vw;
        align-self: center;
        justify-self: center;
        cursor: pointer;
        margin-top: 1.3vw;
        width: 10vw;
        height: 2.4vw;
        gap: 0.4vw;
        transition:
            box-shadow 2s ease,
            background 0.4s ease;
    }
    .buttonSave:active {
        background: #024533;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    }
    .iconButton {
        font-size: 1.8vw;
    }
    .ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
        display: none;
    }

    .ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) {
        pointer-events: none;
    }

    .ant-picker-calendar .ant-picker-cell-selected .pTable {
        color: black !important;
    }

    .ant-picker-calendar .ant-picker-cell-selected .ant-picker-cell-inner {
        transform: scale(1.8);
        z-index: 10;
        position: relative;
        bottom: 3vw;
    }

    .pTable {
        width: 120%;
    }
    .dayTripM {
        color: #017c5b;
        font-weight: bold;
    }
    .dayTripT {
        color: #f9ad09;
        font-weight: bold;
    }
    .dayTripN {
        color: #2f0bfa;
        font-weight: bold;
    }
    .dayTripCD {
        color: #04c3f8;
        font-weight: bold;
    }
    .dayTripCN {
        color: #510889;
        font-weight: bold;
    }
    .delete{
        color: red;
        font-size: 0.7vw;
        cursor: pointer;
    }

    .assignDriversDiv {
        .textDrivers {
            font-size: 1vw;
            text-align: center;
            font-weight: bold;
            font-family: "Lato", sans-serif;
        }
        .selectAndGoBack {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .selectDayTrip {
                font-size: 1.4vw;
            }
            .buttonGoBack {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                border: 1px solid #fff;
                background: #017c5b;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                color: #fff;
                font-size: 1vw;
                font-family: Overpass;
                font-weight: 600;
                text-align: center;
                padding: 0.1vw 1vw;
                align-self: center;
                justify-self: center;
                cursor: pointer;
                transition:
                    box-shadow 2s ease,
                    background 0.4s ease;
            }
        }

        .sectionDriversAndCalendar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            .divDrivers {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 13%;
                height: 45vw;
                overflow-y: scroll;
                .driverCard {
                    display: flex;
                    border: 1px solid rgba(0, 0, 0, 0.25);
                    flex-direction: column;
                    align-items: center;
                    padding: 0.3vw 0.3vw 0vw 0.3vw;
                    gap: 0.2vw;
                    border-radius: 10px;
                    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                    cursor: pointer;
                    .image {
                        width: 3vw;
                    }
                    .name {
                        font-size: 0.85vw;
                        font-family: "Lato", sans-serif;
                        font-weight: bold;
                        margin-bottom: 0.3vw;
                        user-select: none;
                    }
                }
            }
            .divCalendar {
                width: 90%;
            }
        }
    }
}

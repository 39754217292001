.homeDiv {
    background-color: rgb(249, 250, 251);
    height: 83.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .bienvenido {
        font-style: normal;
        font-weight: 700;
        font-family: "Noto Sans";
        background-color: rgba(0, 0, 0, 0.7);
        color: transparent;
        text-shadow: 0px 1.8px 4px #ffff;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        font-size: 4vw;
        margin-bottom: 0.1vh;
    }
    .zeus {
        font-style: normal;
        font-weight: 800;
        font-family: "Noto Sans";
        background-color: rgba(0, 0, 0, 0.7);
        color: transparent;
        text-shadow: 0px 1.8px 4px #ffff;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        font-size: 4vw;
    }
    .img {
        width: 30vw;
    }
    .Form {
        width: 49%;
        margin-top: 3vw;
        h1 {
            color: #6a6a6a;
            font-size: 2vw;
        }
        form {
            display: flex;
            button {
                background-color: #017c5b;
                border-radius: 5px;
                box-shadow: 0px 4px 4px 0px #00000040;
                color: white;
                border: 1px solid white;
                width: 7vw;
                height: 2vw;
                font-weight: 700;
                font-size: 1vw;
                position: relative;
                top: 2vw;
                cursor: pointer;
            }
        }
    }
    .ModuleWorkCards {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2vw;
        margin-top: 2vw;
        margin-left: 0.1vw;
    }
}

.FormSummary {
    .container {
        .firstDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;
            #one {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 55vw;
                margin-top: 1vw;
                .selectDiv {
                    position: relative;
                    bottom: 0.85vw;
                    .pSupervisor {
                        margin-left: 0.1vw;
                        font-weight: 400;
                        font-size: 1.1vw;
                        font-weight: 600;
                        font-family: "Noto Sans Thai", sans-serif;
                    }
                    .select {
                        width: 20vw;
                        height: 3.6vw;
                        border: 1px solid #c4c4c4;
                        border-radius: 5px;
                        font-size: 1.5vw;
                    }
                    .ant-select-selector {
                        height: 3.4vw;
                        border-radius: 5px;
                        border: 0;
                    }
                }
            }
            .dateDiv {
                position: relative;
                bottom: 0.8vw;
                .label {
                    position: relative;
                    top: -0.1vw;
                }
            }
        }
        .label {
            margin-bottom: 0.5vw;
        }
        textarea {
            width: 100%;
            border-color: #c4c4c4;
        }
        .imageDiv {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            img {
                width: 20vw;
                height: 25vw;
            }
            .canvas {
                margin-top: 2vw;
                width: 16vw;
                height: 16vw;
            }

            input[type="file"] {
                display: none;
            }
            .custom-file-upload {
                border: 1px solid #ccc;
                display: inline-block;
                cursor: pointer;
                padding: 0;
                color: #4e8e30;
                background-color: "#4E8E30";
                border-radius: "10px";
                width: 11vw;
                font-size: 1.3vw;
                text-align: center;
                height: 3vw;
            }
            .imageButtons {
                display: flex;
                justify-content: space-between;
                width: 24vw;
                margin-top: 1vw;
            }
        }
        button {
            border-radius: 3px;
            border: 1px solid #fff;
            background: #017c5b;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            color: #fff;
            font-size: 1vw;
            font-family: Overpass;
            font-weight: 600;
            line-height: 21px;
            cursor: pointer;
        }
        .imagesDiv {
            display: flex;
            flex-direction: column-reverse;
        }
        .lastDiv {
            display: flex;
            justify-content: flex-end;
        }
        .errorMessage {
            color: red;
            font-size: 1.6vw;
        }
        .relativeTop {
            position: relative;
            top: 2.5vw;
        }
    }
    .pdf {
        width: 100%;
        height: 70vw;
    }
}

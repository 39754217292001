.ModalTimelinesThisMonth .ant-modal-body {
    padding: 0;
}
.ModalTimelinesThisMonth .ant-modal-content {
    border-radius: 10px;
    .header {
        border-radius: 10px 10px 0px 0px;
        background-color: $green;
        height: 4vw;
        p {
            font-family: "Montserrat";
            font-weight: 700;
            color: #ffffff;
            position: relative;
            top: 0.7vw;
            left: 2vw;
            font-size: 1.25vw;
        }
    }
    .name {
        color: $green;
        font-family: "Lato", sans-serif;
        font-weight: 700;
        border-bottom: 2px solid #4e8e30;
        height: 2.4vw;
        width: 85%;
        margin-left: 7vw;
        margin-top: 1vw;
    }
    .headers {
        display: flex;
        align-self: center;
        margin-left: 3vw;
        width: 93%;
        justify-content: space-between;
        p {
            font-family: "Lato";
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
        }
    }
    hr {
        margin-left: 3vw;
        width: 90%;
    }
    .data {
        display: flex;
        margin-left: 1vw;
        min-height: 5vw;
        width: 90%;
        justify-content: space-between;
        align-self: center;
        p {
            text-align: center;
            font-size: 1vw;
        }
        .button {
            font-family: "Overpass";
            font-style: normal;
            font-weight: 600;
            font-size: 1vw;
            color: #ffffff;
            background: #10b759;
            border: 1px solid #ffffff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            height: 2vw;
        }
    }
    .showingText {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        margin-top: 2vw;
        margin-left: 3vw;
    }
    .divButtonsPages {
        display: flex;
        justify-content: center;
        margin-top: 1.5vw;
        margin-bottom: 2vw;
        width: 60vw;
        .sidesButtons {
            background-color: #f7f7f7;
            border: 2px solid #e9e9e9;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 1vw;
            padding-left: 1vw;
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 7vw;
            height: 3vw;
        }
        .RadioButtonGroup {
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 0;
            border-left: 0;
        }
        .radiobutton {
            width: 3vw;
            height: 3vw;
            color: #000;
            font-size: 1.2vw;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f7f7f7;
            border: 2px solid #e9e9e9;
        }
        .radiobutton:hover {
            color: #337ab7;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
        }
        .radiobuttonSelected {
            width: 3vw;
            height: 3vw;
            color: rgb(255, 255, 255);
            font-size: 1.2vw;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #337ab7;
            border: 2px solid #e9e9e9;
        }
        .radiobuttonSelected:hover {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
        }
    }
}

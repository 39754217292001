.FormTimeline {
    .filtersAndEquips {
        display: flex;
        justify-content: space-between;
        .filters {
            width: 33vw;
            box-shadow: 0px 4px 4px 0px #00000040;
            padding: 1.3vw;
            border-radius: 5px;
            .inventoryText {
                font-style: "Overpass";
                font-weight: 600;
                line-height: 26.88px;
                font-size: 1.6vw;
                color: #017c5b;
                margin-bottom: -1vw;
            }
            .fils {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .select {
                    margin-top: 2vw;
                    height: 2vw;
                    width: 14vw;
                    margin-bottom: 1vw;
                }
                .divDate {
                    margin-top: 1vw;
                    position: relative;
                    width: 14vw;
                    bottom: 0.45vw;
                }
                .ant-picker {
                    width: 14vw;
                }
                .label {
                    margin-bottom: 0vw;
                    line-height: 1.6vw;
                }
            }
            .buttons {
                display: flex;
                justify-content: space-between;
                margin-top: 1vw;
                .green {
                    background-color: #017c5b;
                }
                .yellow {
                    background-color: #fdb600;
                }
                div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 0.5vw;
                    padding-right: 0.5vw;
                    padding-bottom: 0.5vw;
                    padding-top: 0.5vw;
                    box-shadow: 0px 4px 4px 0px #00000040;
                    border-radius: 3px;
                    cursor: pointer;
                    border: 1px solid #ffffff;
                    .icon {
                        color: white;
                        font-size: 1.4vw;
                    }
                    p {
                        color: white;
                        font-size: 0.85vw;
                        text-align: "center";
                        margin-top: 0.5vw;
                        margin-left: 0.6vw;
                        width: 11vw;
                        font-weight: 600;
                    }
                }
            }
        }
        .divEquips {
            width: 47vw;
            height: 29vw;
            box-shadow: 0px 4px 4px 0px #00000040;
            padding: 1.3vw;
            border-radius: 5px;
            overflow-y: auto;
            .EquipTimeline {
                border: 1px solid #10b759;
                border-radius: 5px;
                box-shadow: $basicShadow;
                padding: 0.6vw;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 5px;
                width: 100%;
                min-height: 3vw;
                .equipmentImage {
                    width: 3vw;
                }
                .dataDiv {
                    /* width: 68.5vw; */
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                    align-items: center;
                    div {
                        width: 5vw;
                    }
                    .pFont {
                        font-weight: bold;
                        font-size: 1vw;
                        text-align: center;
                        line-height: 0;
                    }
                    .pFontData {
                        font-weight: 400;
                        font-size: 0.7vw;
                        text-align: center;
                        font-family: "Lato";
                        color: black;
                        line-height: 1vw;
                    }
                    .name {
                        font-size: 0.6vw;
                    }
                    .status {
                        background: #10b759;
                        border: 1px solid #ffffff;
                        box-shadow: $basicShadow;
                        border-radius: 5px;
                        width: 6.2vw;
                        height: 2.2vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .pFontStatus {
                            font-family: "Overpass";
                            font-style: normal;
                            font-weight: 600;
                            color: white;
                            line-height: 0;
                            margin-bottom: -0.4vw;
                            font-size: 1vw;
                        }
                    }
                    .status1 {
                        background: #fdb600;
                        border: 1px solid #ffffff;
                        box-shadow: $basicShadow;
                        border-radius: 5px;
                        width: 6.2vw;
                        height: 2.2vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .pFontStatus {
                            font-family: "Overpass";
                            font-style: normal;
                            font-weight: 600;
                            color: white;
                            line-height: 0;
                            margin-bottom: -0.4vw;
                            font-size: 1vw;
                        }
                    }
                    .status2 {
                        background: #b92309;
                        border: 1px solid #ffffff;
                        box-shadow: $basicShadow;
                        border-radius: 5px;
                        width: 6.2vw;
                        height: 2.2vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .pFontStatus {
                            font-family: "Overpass";
                            font-style: normal;
                            font-weight: 600;
                            color: white;
                            line-height: 0;
                            margin-bottom: -0.4vw;
                            font-size: 1vw;
                        }
                    }
                }
            }
            .showingText {
                font-family: "Lato";
                font-style: normal;
                font-weight: 400;
                margin-top: 2vw;
                margin-left: 0.5vw;
            }
            .divButtonsPages {
                display: flex;
                justify-content: center;
                margin-top: 1.5vw;
                width: 100%;
                .sidesButtons {
                    background-color: #f7f7f7;
                    border: 2px solid #e9e9e9;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-right: 1vw;
                    padding-left: 1vw;
                    cursor: pointer;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    width: 7vw;
                    height: 3vw;
                }
                .RadioButtonGroup {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-right: 0;
                    border-left: 0;
                }
                .radiobutton {
                    width: 3vw;
                    height: 3vw;
                    color: #000;
                    font-size: 1.2vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f7f7f7;
                    border: 2px solid #e9e9e9;
                }
                .radiobutton:hover {
                    color: #337ab7;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    cursor: pointer;
                }
                .radiobuttonSelected {
                    width: 3vw;
                    height: 3vw;
                    color: rgb(255, 255, 255);
                    font-size: 1.2vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #337ab7;
                    border: 2px solid #e9e9e9;
                }
                .radiobuttonSelected:hover {
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    cursor: pointer;
                }
            }
        }
    }
    .columnsNameFormTimeline {
        display: flex;
        width: 100%;
        justify-content: space-around;
    }
    .equips {
        display: flex;
        border: 1px solid #10b759;
        border-radius: 5px;
        box-shadow: $basicShadow;
        padding: 0.6vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 5px;
        width: 100%;
        height: 3vw;
        .equipmentImage {
            width: 3vw;
        }
        .dataDiv {
            width: 73vw;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            align-items: center;
            div {
                width: 5vw;
            }
            .pFont {
                font-weight: bold;
                font-size: 1vw;
                text-align: center;
                line-height: 0;
            }
            .pFontData {
                font-weight: 400;
                font-size: 0.7vw;
                text-align: center;
                font-family: "Lato";
                color: black;
                line-height: 1vw;
            }
            .name {
                font-size: 0.6vw;
            }
            .status {
                background: #10b759;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                border-radius: 5px;
                width: 6.2vw;
                height: 2.2vw;
                display: flex;
                justify-content: center;
                align-items: center;
                .pFontStatus {
                    font-family: "Overpass";
                    font-style: normal;
                    font-weight: 600;
                    color: white;
                    line-height: 0;
                    margin-bottom: -0.4vw;
                    font-size: 1vw;
                }
            }
            .status1 {
                background: #fdb600;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                border-radius: 5px;
                width: 6.2vw;
                height: 2.2vw;
                display: flex;
                justify-content: center;
                align-items: center;
                .pFontStatus {
                    font-family: "Overpass";
                    font-style: normal;
                    font-weight: 600;
                    color: white;
                    line-height: 0;
                    margin-bottom: -0.4vw;
                    font-size: 1vw;
                }
            }
            .status2 {
                background: #b92309;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                border-radius: 5px;
                width: 6.2vw;
                height: 2.2vw;
                display: flex;
                justify-content: center;
                align-items: center;
                .pFontStatus {
                    font-family: "Overpass";
                    font-style: normal;
                    font-weight: 600;
                    color: white;
                    line-height: 0;
                    margin-bottom: -0.4vw;
                    font-size: 1vw;
                }
            }
        }
        .deleteDiv {
            display: flex;
            align-items: flex-start;
            .icon {
                color: red;
                cursor: pointer;
                font-size: 1vw;
            }
        }
    }
}

.FormServiceReportIndustrial {
    margin-bottom: 3vw;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 1.5vw;
    .initialSelects {
        margin-bottom: 3vw;
        .select {
            width: 12vw;
            height: 2.7vw;
            margin-right: 2vw;
            border: 1px solid #c4c4c4;
            border-radius: 5px;
            font-size: 1.5vw;
        }
        .ant-select-selector {
            height: 2.6vw;
            border-radius: 5px;
            border: 0;
        }
    }
    .errorMessage {
        color: red;
        font-size: 1vw;
    }
    .titleSection {
        border-bottom: 1px solid #e3dfdf;
        font-family: Montserrat;
        font-size: 1vw;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        color: #017c5b;
        margin-top: 2vw;
        padding-bottom: 0.3vw;
    }
    .papersSection {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .papersName {
            font-size: 0.9vw;
            color: #000000a6;
            font-weight: 700;
            position: relative;
            top: 7px;
            width: 14.5vw;
        }
        .radioGroup {
            display: flex;
            .ant-radio-wrapper {
                font-size: 0.9vw;
            }
        }
        .divInputsPapers {
            display: flex;
        }
    }
    .tireSection {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .tireName {
            font-size: 0.9vw;
            color: #000000a6;
            font-weight: 700;
            position: relative;
            top: 7px;
            width: 9vw;
            margin-right: -1vw;
        }
    }
    .divAccessories {
        display: flex;
        flex-direction: row;
        margin-top: 1vw;
        box-shadow: 0px 4px 4px 0px #00000040;
        flex-wrap: wrap;
        width: 100%;
        .accessorySection {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 50%;
            border: 1px solid #e3dfdf;
            border-bottom: 0;
            border-left: 0;
            padding: 0.4vw;
            .text {
                color: #000000b2;
                text-align: justify;
                font-size: 0.9vw;
            }
            .accessory {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                .category {
                    border: 1px solid #9daebe;
                    background-color: #fdb600;
                    border-radius: 5px;
                    width: 7vw;
                    /* font-size: 0.8vw; */
                    font-weight: 700;
                    text-align: center;
                    align-self: center;
                }
                .divColumnsNames {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .columnName {
                        font-size: 0.8vw;
                        color: #000000a6;
                        font-weight: 700;
                    }
                }
                .option {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .nameOption {
                        font-size: 0.8vw;
                        color: #000000a6;
                        font-weight: 700;
                        width: 5vw;
                    }
                    .radioGroup {
                        display: flex;
                        position: relative;
                        left: 3vw;
                        .ant-radio-wrapper {
                            font-size: 0.8vw;
                            user-select: none;
                            .ant-radio {
                                width: 1vw;
                                height: 1vw;

                                .ant-radio-inner {
                                    width: 1vw;
                                    height: 1vw;
                                }
                                .ant-radio-inner::after {
                                    width: 0.5vw;
                                    height: 0.5vw;
                                }

                                .ant-radio-input {
                                    width: 1vw;
                                    height: 1vw;
                                }
                            }
                        }
                    }
                    .inputDiv {
                        position: relative;
                        bottom: 12px;
                    }
                    .canvasFace {
                        width: 4.7vw;
                        height: 4.5vw;
                        border-width: 0px, 0px, 0px, 0px;
                        border-style: solid;
                        border-color: #e3e3e380;
                        margin-bottom: 1vw;
                        position: relative;
                        bottom: 15px;
                    }
                }
            }
            .accessory.yellow {
                background-color: #fdb600;
                box-shadow: 0px 4px 4px 0px #00000040;
                border-radius: 3px;
                padding: 0.5vw;
            }
        }

        .accessorySectionAirAndFridge {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 33.33%;
            border: 1px solid #e3dfdf;
            border-bottom: 0;
            border-left: 0;
            padding: 0.4vw;
            .text {
                color: #000000b2;
                text-align: justify;
                font-size: 0.9vw;
            }
            .accessory {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                .category {
                    border: 1px solid #9daebe;
                    background-color: #fdb600;
                    border-radius: 5px;
                    width: 7vw;
                    /* font-size: 0.8vw; */
                    font-weight: 700;
                    text-align: center;
                    align-self: center;
                }
                .divColumnsNames {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .columnName {
                        font-size: 0.8vw;
                        color: #000000a6;
                        font-weight: 700;
                    }
                }
                .option {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .nameOption {
                        font-size: 0.8vw;
                        color: #000000a6;
                        font-weight: 700;
                        width: 13vw;
                        margin-right: 1vw;
                    }
                    .radioGroup {
                        display: flex;
                        position: relative;
                        left: 3vw;
                        .ant-radio-wrapper {
                            font-size: 0.8vw;
                            user-select: none;
                            .ant-radio {
                                width: 1vw;
                                height: 1vw;

                                .ant-radio-inner {
                                    width: 1vw;
                                    height: 1vw;
                                }
                                .ant-radio-inner::after {
                                    width: 0.5vw;
                                    height: 0.5vw;
                                }

                                .ant-radio-input {
                                    width: 1vw;
                                    height: 1vw;
                                }
                            }
                        }
                    }
                    .inputDiv {
                        position: relative;
                        bottom: 12px;
                    }
                    .canvasFace {
                        width: 4.7vw;
                        height: 4.5vw;
                        border-width: 0px, 0px, 0px, 0px;
                        border-style: solid;
                        border-color: #e3e3e380;
                        margin-bottom: 1vw;
                        position: relative;
                        bottom: 15px;
                    }
                }
            }
            .accessory.yellow {
                background-color: #fdb600;
                box-shadow: 0px 4px 4px 0px #00000040;
                border-radius: 3px;
                padding: 0.5vw;
            }
        }
    }
    .newComp {
        display: flex;
        margin-top: 1.5vw;
        justify-content: flex-end;
    }
    .radioG {
        display: flex;
        justify-content: flex-start;
        width: 20vw;
        margin-top: 1vw;
        margin-bottom: 2.5vw;
    }
    .sparePartsDiv {
        display: flex;
        width: 50vw;
        margin-top: 1.5vw;
        justify-content: space-between;
        .closeIcon {
            color: red;
        }
    }
    .label {
        color: #000000a6;
        font-family: Lato;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        font-size: 1vw;
        margin-bottom: 0.7vw;
    }

    .divDate {
        position: relative;
        top: -0.5vw;
        margin-top: 0.7vw;
        .label {
            position: relative;
            top: -0.1vw;
        }
    }
    .radioG {
        display: flex;
        margin-left: 1vw;
        width: 100%;
        justify-content: space-between;
        .radio {
            font-size: 1vw;
            color: #000000a6;
            font-weight: 600;
        }
    }
    .imageDiv {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        img {
            width: 15vw;
            height: 25vw;
        }
        .canvas {
            width: 100%;
            height: 18.5vw;
            border-width: 0px, 0px, 0px, 0px;
            border-style: solid;
            border-color: #e3e3e380;
            box-shadow: 0px 4px 4px 0px #00000040;
        }

        input[type="file"] {
            display: none;
        }
        .custom-file-upload {
            border: 1px solid #ccc;
            display: inline-block;
            cursor: pointer;
            padding-left: 0.7vw;
            padding-right: 0.7vw;
            color: #ffffff;
            background-color: #4e8e30;
            border-radius: 10px;
            font-size: 1vw;
            text-align: center;
            height: 3.5vw;
            width: 6vw;
        }
        .imageButtons {
            display: flex;
            justify-content: space-between;
            width: 15vw;
            margin-top: 1vw;
        }
    }
    .save {
        border-radius: 3px;
        border: 1px solid #fff;
        background: #017c5b;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #fff;
        font-size: 1vw;
        font-family: Overpass;
        font-weight: 600;
        line-height: 21px;
        padding: 0.3vw 1.8vw;
        align-self: center;
        justify-self: center;
        cursor: pointer;
        margin-bottom: 2vw;
        margin-top: 1.3vw;
        transition:
            box-shadow 2s ease,
            background 0.4s ease;
    }
    .save:active {
        background: #024533;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    }
}

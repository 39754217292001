.cardDiv {
  border: 3px solid #10b759;
  width: 18vw;
  min-height: 30vw;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  border: 1px solid #10b759;
  backdrop-filter: blur(2px);
  border-radius: 3px;
  cursor: pointer;
  .equipmentImage {
    width: 10vw;
    height: 6vw;
    padding-right: 1vw;
    float: left;
  }
  .dataDiv {
    margin-top: 1vw;
    width: 16vw;
    display: flex;
    flex-wrap: wrap;
    .row {
      display: flex;
    }
    div {
      margin-right: 2vw;
    }
    .pFont {
      color: #6a6a6a;
      font-weight: 700;
      font-family: "Lato";
      margin-bottom: 0vw;
      font-size: 1.4vw;
    }
    .pFontData {
      color: black;
      font-weight: 400;
      font-family: "Lato";
      margin-bottom: 0.5vw;
      font-size: 0.8vw;
    }
    .big {
      width: 9vw;
    }
  }
}

.LoginDiv {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100vw;
  height: 100vh;
  .Login {
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url("../../../assets/images/backgroundLogin.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;

    .formDiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      padding: 2vw 2.5vw;
      box-shadow: 0px 4px 4px 0px #00000040;
      border-radius: 15px;
      width: 33%;
      .imageLogoAndTitle {
        width: 15vw;
      }
      .welcomeText {
        color: #454545;
        font-weight: 600;
        font-family: "Roboto", sans-serif;
        text-align: center;
        font-size: 1.3vw;
        width: 89%;
      }
      .inputsDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* width: 60%; */
        .errorMessage {
          margin-bottom: 2vw;
          margin-top: -1.5vw;
        }
        .checkbox {
          display: flex;
          justify-content: space-between;
          align-self: flex-end;
          width: 12vw;
          margin-top: -1.7vw;
          .box {
            background: #ffffff;
            border: 0.5px solid #c4c4c4;
            border-radius: 2px;
            width: 1vw;
            height: 1vw;
            cursor: pointer;
          }
          .showed {
            background-image: url("../../../assets/images/showed.JPG");
            background-size: contain;
          }
          .box:hover {
            border: 0.5px solid #10b759;
          }
          p {
            color: #c4c4c4;
            font-family: "Roboto", sans-serif;
            font-weight: 300;
            position: relative;
            bottom: 3px;
          }
        }
      }
      .boton {
        border-radius: 5px;
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        box-shadow: 0px 4px 4px 0px #00000040;
        width: 25vw;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        border: 0;
        font-size: 1.8vw;
        color: white;
        font-weight: 400;
        background-color: #00ac4b;
        cursor: pointer;
        transition: background 0.5s ease;
      }
      .boton:hover {
        background-color: #028f3f;
      }
      .bottomDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1vw;
        p {
          text-decoration-line: underline;
          font-family: "Roboto", sans-serif;
          cursor: pointer;
        }
        .ARegister {
        }
        .APassword {
          color: #3c2f3d8c;
          font-size: 1vw;
        }
        .divCopyright {
          display: flex;
          align-items: center;
          .copyright {
            color: #d1dbe5;
          }
          .iconCopy {
          }
        }
      }
    }
  }
}

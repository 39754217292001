.ModalSearchEquipment .ant-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .titleModal {
    color: #10b759;
    font-family: "Overpass";
    font-weight: 600;
    font-size: 2vw;
  }
  .selectsDependency {
    margin-left: 1.5vw;
    margin-bottom: 2vw;
    .select {
      width: 35vw;
      height: 3.6vw;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      font-size: 1.5vw;
    }
    .ant-select-selector {
      height: 3.4vw;
      border-radius: 5px;
      border: 0;
    }
  }
  .a {
    margin-top: 1.5vw;
  }
  .inputDiv {
    .e {
      margin-bottom: 1vw;
    }
    .label {
      color: $green;
      font-family: "Overpass";
      font-weight: 600;
      font-size: 2vw;
      font-size: 1.4vw;
    }
    input {
      margin-bottom: 1vw;
      height: 3vw;
    }
  }
  .rangepicker {
    width: 100%;
    margin-bottom: 2vw;
  }
  .label {
    color: $green;
    font-family: "Overpass";
    font-weight: 600;
    font-size: 2vw;
    font-size: 1.4vw;
  }
  .select {
    width: 100%;
    height: 3.6vw;
    margin-right: 2vw;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    font-size: 1.5vw;
  }
  .ant-select-selector {
    height: 3.4vw;
    border-radius: 5px;
    border: 0;
  }
  .divExports {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.5vw;
    .divXLSX {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: #10b759;
      border: 1px solid #ffffff;
      box-shadow: $basicShadow;
      border-radius: 5px;
      font-family: "Overpass";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3vw;
      line-height: 21px;
      text-align: center;
      color: white;
      width: 18.5vw;
      height: 3.5vw;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
    }
    .divPDF {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: $orange;
      border: 1px solid #ffffff;
      box-shadow: $basicShadow;
      border-radius: 5px;
      font-family: "Overpass";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3vw;
      line-height: 21px;
      text-align: center;
      color: white;
      width: 18.5vw;
      height: 3.5vw;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
    }
  }
  .divButton {
    width: 100%;
    display: flex;
    justify-content: center;
    .button {
      background: $green;
      color: white;
      font-size: 1vw;
      font-weight: 600;
      font-family: "Overpass";
      border: 1px solid #ffffff;
      box-shadow: $basicShadow;
      border-radius: 5px;
      height: 2.8vw;
      .plus {
        color: white;
      }
    }
  }
}
.ModalSearchEquipment .ant-modal-content {
  border-radius: 5px;
}

.Tickets {
    display: flex;
    .Form {
        width: 60%;
        margin-top: 3vw;
        .grayHeader {
            display: flex;
            background-color: #e3dfdf;
            box-shadow: 0px 4px 4px 0px #00000040;
            width: 100%;
            height: 3vw;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px 5px 0 0;
            padding: 2vw;
            padding-left: 1vw;
            padding-right: 1vw;
            h1 {
                color: #6a6a6a;
                font-size: 1.2vw;
                position: relative;
                top: 5px;
                font-weight: 700;
            }
        }
        .subPanel {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0vw;
            padding: 2vw;
            padding-left: 1vw;
            padding-right: 1vw;
            .select .ant-select-selector {
                width: 15vw;
                height: 2.5vw;
                line-height: 2.5vw;
                border: 1px solid #c4c4c4;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border: 0;
                box-shadow: 0px 4px 4px 0px #00000040;
                font-weight: 600;
                color: #6a6a6a;
            }
            h3 {
                font-size: 1vw;
            }
            .inputPhoto {
                border: 1px solid #c4c4c4;
                border-radius: 5px;
                width: 20vw;
                height: 20vw;
                margin-right: 0.7vw;
            }
        }
        form {
            display: flex;
            align-items: center;
            button {
                background-color: #017c5b;
                border-radius: 5px;
                box-shadow: 0px 4px 4px 0px #00000040;
                color: white;
                border: 1px solid white;
                width: 7vw;
                height: 2vw;
                font-weight: 700;
                font-size: 1vw;
                position: relative;
                top: 2vw;
                cursor: pointer;
            }
        }
        .button {
            background-color: #017c5b;
            box-shadow: 0px 4px 4px 0px #00000040;
            font-weight: 700;
            font-size: 1.1vw;
            margin-top: 2vw;
            color: white;
            border: 0;
            border-radius: 5px;
            padding-left: 1.6vw;
            padding-right: 1.6vw;
            padding-top: 0.4vw;
            padding-bottom: 0.4vw;
            cursor: pointer;
            transition:
                box-shadow 0.4s ease,
                background 0.4s ease;

            &:active {
                background-color: #02392a;
                box-shadow: 0px 2px 2px 0px #00000040 inset;
            }
        }
    }
    .ModuleWorkCards {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2vw;
        margin-top: 2vw;
        margin-left: 0.1vw;
    }
}

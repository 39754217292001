.body {
    display: flex;
    flex-direction: row;
    .form {
        width: 52vw;
    }
    .MenuDiv {
        margin-left: 2vw;
        position: sticky;
        top: 5vh;
        height: 10vh;
        .MenuTitle {
            margin-left: 1vw;
            margin-top: -7vh;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 2.2vw;
            color: #10b759;
        }
        .select {
            margin-left: 0.6vw;
            margin-top: 2vh;
            background-color: #10b759;
            color: white;
            font-size: 1.7vw;
            font-weight: bold;
            padding-left: 5vw;
            width: 25vw;
            height: 8vh;
            MenuItem {
                background-color: yellow;
            }
        }
        .selected {
            background-color: #fdb600;
            width: 25vw;
            height: 8vh;
            margin-left: 0.6vw;
            font-size: 1.7vw;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: white;
        }
        .fileDiv {
            section {
                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 3vh;
                    border-style: dashed;
                    border-width: 1px;

                    border-color: gray;
                    width: 26vw;
                    height: 21vh;
                }
            }
            .nameFile{
                font-weight: bold;
                display: flex;
                margin-top: 3vh;
                width: 26vw;
                font-size: 1vw;
                justify-content: center;
                
            }

            .icon {
                font-size: 5vw;
                margin-right: 1vw;
                margin-bottom: 3vh;
                color: #4e8e30;
            }
            .text {
                margin-top: 3vh;
                color: #4e8e30;
                width: 10vw;
                font-size: 1.3vw;
            }
        }
        .buttonsDiv {
            display: flex;
            justify-content: space-around;
            width: 26vw;
            margin-top: 2vh;
            .inputFile{
                position: absolute;
                left: 10vw;
            }
        }
        .buttonSave {
            margin-top: 3vh;
            width: 26vw;
            display: flex;
            justify-content: center;
        }
    }
}

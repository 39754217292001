.ModuleWorkCard {
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 18.6vw;
    height: 17vw;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 3vw;
    margin-right: 2.2%;
    margin-bottom: 1vw;
    transition:
        box-shadow 0.4s ease,
        background 0.4s ease;
    cursor: pointer;
    .imgModule {
        width: 2.7vw;
        margin-top: 0vw;
        user-select: none;
    }
    h2 {
        user-select: none;
        font-size: 1.6vw;
        color: #6a6a6a;
        font-weight: 700;
    }
    p {
        color: #afafaf;
        font-size: 0.9vw;
        user-select: none;
    }
    &:active {
        box-shadow: 0px 4px 4px 0px #00000040 inset;
        background: #017c5b26;
    }
}

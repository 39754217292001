.inputDiv {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    .label {

        margin-left: 0.1vw;
        font-weight: 400;
        font-size: 1.1vw;
        font-weight: 600;
        font-family: "Noto Sans Thai", sans-serif;
    }
    .divInput {
        margin-top: 0.75vw;
        display: flex;
        flex-direction: column;
    }
    .inputHeader {
        width: 20vw;
        height: 3vw;
        border: rgb(255, 255, 255);
        background: #ebedf0;
        border-radius: 15px;
        padding-left: 1vw;
        font-family: "Overpass";
        font-style: normal;
        font-weight: normal;
    }
    .inputHeader:focus {
        outline: none;
    }
    .inputLabel:focus {
        outline: none
    }
    .inputLabel {
        border-radius: 5px;
        border: 0.5px solid #c4c4c4;
        padding-left: 0.5vw;
        padding-right: 3.5vw;
        border: 1px solid #9c9595;
        font-family: "Roboto";
        width: 24.75vw;
        height: 4vw;
        margin-bottom: 1.7vw;
    }
    .inputLabelBordered {
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        width: 14vw;
        height: 2vw;
    }
    .inputLabel::placeholder {
        color: rgb(127, 129, 141);
    }
    .inputLabelBordered::placeholder {
        color: rgb(127, 129, 141);
        text-align: center;
        font-weight: 700;
        font-family: Lato;
    }
    .textArea {
        border-radius: 8px;
        border-width: thin;
        border-color: "#CDCDCD";
        height: 12.5vw;
        width: 46.96vw;
        font-size: 2vw;
    }
    .iconDiv {
        display: flex;
        justify-content: flex-end;
        margin-right: 1vw;
        .iconEye {
            color: #ababab;
            font-size: 2vw;
            position: relative;
            bottom: 3vw;
            left: 36vw;
        }
        .iconEmail {
            color: #ababab;
            font-size: 2vw;
            position: relative;
            bottom: 3vw;
        }
    }
    .icon {
        font-size: 2vw;
        position: relative;
        top: 0.35vw;
    }
}

.MyAccount {
    padding: 1vw;
    padding-left: 2vw;
    .titleDiv {
        display: flex;
        justify-content: space-between;
        width: 22.7vw;
        .inventoryText {
            font-style: "Overpass";
            font-weight: 600;
            line-height: 26.88px;
            font-size: 1.7vw;
            color: #10b759;
        }
    }
    .body {
        display: flex;
        flex-direction: row;
        .form {
            width: 100%;
            margin-top: 1vw;
            p {
                color: #6a6a6a;
                font-family: Overpass;
                font-size: 1.4vw;
                font-style: normal;
                font-weight: 400;
                line-height: 21px; /* 175% */
            }
            .checkbox {
                display: flex;
                justify-content: space-between;
                width: 13.3vw;
                margin-top: -2vw;
                .box {
                    background: #ffffff;
                    border: 0.5px solid #c4c4c4;
                    border-radius: 2px;
                    cursor: pointer;
                    width: 1.3vw;
                    height: 1.3vw;
                }
                .showed {
                    background-image: url("../../../assets/images/showed.JPG");
                    background-size: contain;
                }
                .box:hover {
                    border: 0.5px solid #10b759;
                }
                p {
                    color: #c4c4c4;
                    font-family: "Noto Sans Thai", sans-serif;
                    font-weight: 300;
                    line-height: 1.5vw;
                    font-size: 1.2vw;
                }
            }
        }
    }
}

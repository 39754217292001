.ListWithPages {
    display: flex;
    position: relative;
    width: 78vw;
    left: -2vw;
    .Pagination{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 81.5vw;
    }
    .Pagination1{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 81.5vw;
        flex-wrap: wrap;
    }
    .showingText {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        margin-top: 2vw;
        margin-left: 0.5vw;
    }
    .divButtonsPages {
        display: flex;
        justify-content: center;
        margin-top: 1.5vw;
        .sidesButtons {
            background-color: #f7f7f7;
            border: 2px solid #e9e9e9;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 1vw;
            padding-left: 1vw;
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 7vw;
            height: 3vw;
        }
        .RadioButtonGroup {
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 0;
            border-left: 0;
        }
        .radiobutton {
            width: 3vw;
            height: 3vw;
            color: #000;
            font-size: 1.2vw;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f7f7f7;
            border: 2px solid #e9e9e9;
        }
        .radiobutton:hover {
            color: #337ab7;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
        }
        .radiobuttonSelected {
            width: 3vw;
            height: 3vw;
            color: rgb(255, 255, 255);
            font-size: 1.2vw;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #337ab7;
            border: 2px solid #e9e9e9;
        }
        .radiobuttonSelected:hover {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
        }
    }
}

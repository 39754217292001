.FormServiceReport {
  .boton {
    width: 15vw;
    height: 3.5vw;
    font-size: 1.3vw;
    font-weight: bold;
  }
  .firstRep {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 3vw;
    margin-bottom: 3vw;
    .div-equipment {
      display: flex;
      flex-wrap: wrap;
      width: 75vw;
      .buttonUnselect {
        width: 10vw;
        height: 3vw;
        font-size: 1.1vw;
        color: white;
        font-family: Overpass;
        font-style: normal;
        font-weight: bold;
        padding-bottom: 0.5vw;
        background-color: #fdb600;
        border: 1px solid #ffffff;
        box-shadow: 0px 4px 4px 0px #00000040;
        margin-top: 1vw;
      }
    }

    .firstFields {
      display: flex;
      justify-content: space-between;
      width: 52vw;
      .label1 {
        margin-bottom: 0.5vw;
        font-weight: bold;
      }
      .select {
        width: 10vw;
        height: 2.5vw;
        margin-top: -0.05vw;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
      }
    }
    .featuresText {
      margin-top: 2.5vw;
      color: #c4c4c4;
      font-size: 1.8vw;
    }
    hr {
      color: "#C4C4C4";
      width: 75vw;
      margin-top: -1vw;
    }
    .divSearchEquips {
      .Selectedd {
        background: $green;
        color: white;
        font-weight: 600;
        font-family: "Overpass";
        border: 1px solid #ffffff;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        height: 2.2vw;
        font-size: 1vw;
        border-radius: 8px;
        min-width: 10vw;
        .plus {
          color: white;
        }
      }
      .closeIcon {
        color: red;
        position: relative;
        right: 0.8vw;
        bottom: 1vw;
        font-size: 1.4vw;
      }
    }
    .dataFields {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5vw;
    }
    .dataFields1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1.5vw;
      .label1 {
        margin-bottom: -0.15vw;
        font-weight: bold;
      }
      .select {
        margin-right: 2.5vw;
        width: 11vw;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
      }
    }
    .radioG {
      display: flex;
      justify-content: space-between;
      margin-top: 1vw;
      .radio {
        font-size: 1.7vw;
      }
    }
    .descriptionDiv {
      .labelServiceReq {
        margin-bottom: -0.15vw;
        margin-top: 0.5vw;
        font-weight: bold;
      }
      .select {
        margin-right: 2.5vw;
        width: 23vw;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
      }
      .serviceDescription {
        font-weight: bold;
        margin-top: 2vw;
      }
      textarea {
        width: 75vw;
        height: 12.5vw;
        margin-bottom: 2.5vw;
        background: rgba(189, 189, 190, 0.05);
        border: 0.5px solid rgba(0, 0, 0, 0.08);
        box-sizing: border-box;
        border-radius: 3px;
      }
    }
  }
  .secondSectionRep {
    width: 75vw;
    .newComp {
      display: flex;
      margin-top: 1.5vw;
      justify-content: flex-end;
    }
    hr {
      color: "#C4C4C4";
      width: 75vw;
      margin-top: -1vw;
    }
    .radioG {
      display: flex;
      justify-content: space-between;
      width: 20vw;
      margin-top: 1vw;
      margin-bottom: 2.5vw;
    }
    .sparePartsDiv {
      display: flex;
      width: 75vw;
      margin-top: 1.5vw;
      justify-content: space-between;
      .closeIcon {
        color: red;
      }
    }
    .processDescription {
      font-weight: bold;
      margin-top: 2.5vw;
    }
    textarea {
      width: 75vw;
      height: 12.5vw;
      margin-bottom: 2.5vw;
      background: rgba(189, 189, 190, 0.05);
      border: 0.5px solid rgba(0, 0, 0, 0.08);
      box-sizing: border-box;
      border-radius: 3px;
    }
    .observations {
      font-weight: bold;
    }

    .fileDiv {
      section {
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1.5vw;
          border-style: dashed;
          border-width: 1px;
          border-color: gray;
          width: 75vw;
          height: 12.5vw;
          .inputDrop {
            width: 75vw;
            height: 12.3vw;
            position: absolute;
            opacity: 0;
          }
        }
      }
      .icon {
        font-size: 5vw;
        margin-right: 1vw;
        margin-bottom: 1.5vw;
        color: #4e8e30;
      }
      .text {
        margin-top: 1.5vw;
        color: #4e8e30;
        width: 10vw;
        font-size: 1.3vw;
      }
    }
    .buttonsDiv {
      display: flex;
      justify-content: space-around;
      width: 75vw;
      margin-top: 1vw;
      .custom-file-upload {
        border: 1px solid #4e8e30;
        cursor: pointer;
        color: #4e8e30;
        border-radius: 10px;
        text-align: center;
        width: 15vw;
        height: 4vw;
        font-size: 1.6vw;
        input {
          display: none;
        }
      }

      .inputFile {
        position: absolute;
        left: 10vw;
      }
    }
    .canvas {
      height: 0;
    }
    .images {
      width: 75vw;
      flex-wrap: wrap;
      background-color: rgba(0, 0, 0, 0.164);
      img {
        width: 24vw;
        height: 28vw;
        margin: 1vw;
      }
    }
    .buttonSave {
      margin-top: 1.5vw;
      width: 75vw;
      display: flex;
      justify-content: center;
    }
  }
}

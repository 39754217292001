.FormEquipmentSystem {
    margin-bottom: 3vw;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 1.5vw;
    .titleSection {
        border-bottom: 1px solid #e3dfdf;
        font-family: Montserrat;
        font-size: 1vw;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        color: #868686;
        margin-top: 2vw;
        padding-bottom: 0.3vw;
    }
    .imageDiv {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        img {
            width: 15vw;
            height: 25vw;
        }
        .canvas {
            width: 15vw;
            height: 18.5vw;
        }

        input[type="file"] {
            display: none;
        }
        .custom-file-upload {
            border: 1px solid #ccc;
            display: inline-block;
            cursor: pointer;
            padding-left: 0.7vw;
            padding-right: 0.7vw;
            color: #ffffff;
            background-color: #4E8E30;
            border-radius: 10px;
            font-size: 1vw;
            text-align: center;
            height: 3.5vw;
            width: 6vw;
        }
        .imageButtons {
            display: flex;
            justify-content: space-between;
            width: 15vw;
            margin-top: 1vw;
        }
    }
    .labelDate,
    .label {
        color: #000000a6;
        font-family: Lato;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        font-size: 1vw;
        margin-bottom: 0.7vw;
    }
    .divInput {
        margin-top: 0;
    }
    .divInputs {
        display: flex;
        align-items: center;
        input {
            font-family: Lato;
            font-weight: 700;
            text-align: left;
        }
    }
    .save {
        border-radius: 3px;
        border: 1px solid #fff;
        background: #017c5b;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #fff;
        font-size: 1vw;
        font-family: Overpass;
        font-weight: 600;
        line-height: 21px;
        padding: 0.2vw 0.6vw;
        align-self: "center";
        cursor: pointer;
    }
    .initialSelects {
        .select {
            width: 13.5vw;
            /* height: 3.6vw; */
            margin-right: 2vw;
            border: 1px solid #c4c4c4;
            border-radius: 5px;
            font-size: 1.5vw;
        }
        .ant-select-selector {
            /* height: 3.4vw; */
            border-radius: 5px;
            border: 0;
        }
    }
    .selectEquips {
        width: 20vw;
        /* height: 3.6vw; */
        margin-right: 2vw;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        font-size: 1.5vw;
        .ant-select-selector {
            /* height: 3.4vw; */
            border-radius: 5px;
            border: 0;
        }
    }
    .titleColumn {
        color: rgba(0, 0, 0, 0.6509803922);
        font-family: Lato;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        font-size: 1vw;
        margin-bottom: 0.7vw;
    }
    .inputPhoto {
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        width: 7vw;
        height: 2vw;
        margin-right: 0.7vw;
    }
    .errorMessage {
        color: red;
    }
    .iconBrush{
        cursor: pointer;
        font-size: 1vw;
    }
    .radioG {
        display: flex;
        margin-left: 1vw;
        width: 40vw;
        justify-content: space-between;
        .radio {
            font-size: 1vw;
        }
    }
    .first {
        display: flex;
        justify-content: space-between;
        margin-top: 3vw;
        margin-bottom: 3vw;
        .divSelect {
            position: relative;
            top: 1vw;
            .titleSelect {
                margin-top: 0.125vw;
                margin-bottom: -0.5vw;
                margin-top: -1vw;
                margin-left: 0.1vw;
                font-weight: 400;
                font-size: 1.1vw;
                font-weight: 600;
                font-family: "Noto Sans Thai", sans-serif;
            }
            .select {
                width: 11vw;
                height: 3vw;
                margin-right: 3vw;
                border: 1px solid #c4c4c4;
                border-radius: 5px;
                font-size: 1.3vw;
                margin-top: 0.75vw;
            }
            .ant-select-selector {
                /* height: 2.7vw; */
                border-radius: 5px;
                border: 0;
            }
        }
    }
    .featuresText {
        color: #c4c4c4;
        font-size: 1.8vw;
    }
    hr {
        color: "#C4C4C4";
        margin-top: -1vw;
    }
    .featuresFirstDiv {
        margin-top: 2.75vw;
        width: 73.5vw;
        display: flex;
        justify-content: space-between;
    }
    .featuresDiv {
        margin-top: 1.5vw;
        height: 29.5vw;
        width: 73.5vw;
        justify-content: space-between;
        display: flex;
        margin-bottom: 3.5vw;

        .firstInfoDiv {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 2vh;
            height: 25vw;
        }
        .secondInfoDiv {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            div {
                p {
                    margin-left: 0.1vw;
                    font-weight: 400;
                    font-size: 1.1vw;
                    font-weight: 600;
                    font-family: "Noto Sans Thai", sans-serif;
                    margin-bottom: 0.5vw;
                }
            }
        }
        .thirdInfoDiv {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            div {
                p {
                    margin-left: 0.1vw;
                    font-weight: 400;
                    font-size: 1.1vw;
                    font-weight: 600;
                    font-family: "Noto Sans Thai", sans-serif;
                    margin-bottom: 0.5vw;
                }
            }
        }
        .buttonDeleteDate {
            background-color: $orange;
            color: white;
            border: 1px solid #000;
            box-shadow: $basicShadow;
            /* width: 8vw; */
            /* height: 2vw; */
            /* margin-left: 1vw; */
            font-size: 1vw;
            cursor: pointer;
        }
    }
    .risk {
        margin-top: 3vh;
        width: 73.5vw;
        .riskTitle {
            margin-bottom: 5vh;
            font-size: 1.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .risksDiv {
            display: flex;
            margin-top: 8vh;
            justify-content: space-between;
        }
        .risksDiv1 {
            display: flex;
            justify-content: space-between;
            margin-top: 5vh;
        }
    }
    .checkBoxes {
        margin-top: 5vh;
        margin-bottom: 8vh;
        display: flex;
        justify-content: space-between;
        div {
            display: flex;
            flex-direction: column;
            p {
                font-size: 1.5vw;
                margin-bottom: -1vh;
            }
            .radioG {
                .radio {
                    margin-top: 3vh;
                    font-size: 1.5vw;
                }
            }
            .radioGH {
                display: flex;
                flex-direction: row;
                .radio {
                    margin-top: 3vh;
                    font-size: 1.5vw;
                }
            }
        }
    }
    .secondSection {
        width: 73.5vw;
        .title {
            font-size: 1.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .newComp {
            display: flex;
            margin-top: 3vh;
            justify-content: flex-flex-end;
        }
        .comp {
            display: flex;
            margin-top: 7vh;
            justify-content: space-between;
            .closeIcon {
                color: red;
            }
        }
        .firstCheckBoxes {
            margin-top: 10vh;
            margin-bottom: 8vh;
            display: flex;
            justify-content: space-between;
            div {
                display: flex;
                flex-direction: column;
                p {
                    font-size: 1.5vw;
                    margin-bottom: -1vh;
                }
                .radioG {
                    .radio {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                }
                .radioGH {
                    display: flex;
                    flex-direction: row;
                    .radio {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                }
            }
        }
        .secondCheckBoxes {
            margin-top: 5vh;
            margin-bottom: 8vh;
            display: flex;
            justify-content: space-around;
            div {
                display: flex;
                flex-direction: column;
                p {
                    font-size: 1.5vw;
                    margin-bottom: -1vh;
                }
                .radioG {
                    .radio {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                }
                .radioGH {
                    display: flex;
                    flex-direction: row;
                    .radio {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                }
            }
        }
    }
    .third {
        .title1 {
            display: flex;
            justify-content: center;
            font-size: 1.5vw;
        }
        .thirdCheckBoxes {
            margin-top: 5vh;
            margin-bottom: 8vh;
            display: flex;
            justify-content: space-around;
            div {
                display: flex;
                flex-direction: column;
                p {
                    font-size: 1.5vw;
                    margin-bottom: -1vh;
                }
                .radioG {
                    .radio {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                }
                .radioGH {
                    display: flex;
                    flex-direction: row;
                    .radio {
                        margin-top: 3vh;
                        font-size: 1.5vw;
                    }
                    .firstDivRadios {
                        margin-left: 1.9vw;
                        margin-top: 0.4vh;
                    }
                    .secondDivRadios {
                        margin-left: 5.9vw;
                    }
                }
            }
            .littleTexts {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 12vw;
                .littleText {
                    font-size: 1.2vw;
                }
            }
        }
        .asignatedAndShared {
            display: flex;
            flex-direction: row;
            justify-content: flex-flex-end;
            .select {
                width: 17.5vw;
                height: 3.6vw;
                margin-right: 2vw;
                border: 1px solid #c4c4c4;
                border-radius: 5px;
                font-size: 1.5vw;
            }
            
            .ant-select-selector {
                height: 3.4vw;
                border-radius: 5px;
                border: 0;
            }
        }
        textarea {
            width: 78vw;
            height: 15vh;
            margin-bottom: 5vh;
            background: rgba(189, 189, 190, 0.05);
            border: 0.5px solid rgba(0, 0, 0, 0.08);
            box-sizing: border-box;
            border-radius: 3px;
        }
    }
}

.FormTraining {
    .titleDiv {
        display: flex;
        justify-content: space-between;
        width: 22.7vw;
        .inventoryText {
            font-style: "Overpass";
            font-weight: 600;
            line-height: 26.88px;
            font-size: 1.7vw;
            color: #10b759;
        }
    }
    .row {
        display: flex;
        justify-content: space-between;
        width: 96%;
        .select {
            margin-top: 2vw;
            width: 10.6vw;
        }
        .selectBig {
            width: 23.7vw;
        }
        .divDate{
            position: relative;
            bottom: 0.45vw;
        }
        .label {
            margin-bottom: 0.3vw;
        }
        .inputDiv {
            .divInput {
                margin-top: 0vw;
            }
        }
    }
    .inputDiv {
        .divInput {
            input{
                width: 20vw;
            }
        }
    }
    .search {
        margin-left: 1vw;
        /* height: 2.6vw; */
        /* width: 5.4vw; */
        position: relative;
        top: 0.5vw;
        background: #10b759;
        border: 1px solid #ffffff;
        box-shadow: $basicShadow;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        cursor: pointer;
    }
    .customeSelect {
        border: 1px solid #000000;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        width: 7.5vw;
        height: 2vw;
        margin-top: 0.5vw;
        margin-left: 1vw;
        justify-content: space-around;
        font-size: 1.1vw;
        cursor: pointer;
        .icon {
            position: relative;
            top: 0.4vw;
        }
    }
    .inputHeader {
        height: 2.5vw;
    }
    .equips {
        border: 1px solid #10b759;
        border-radius: 5px;
        box-shadow: $basicShadow;
        padding: 0.6vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 5px;
        width: 79vw;
        height: 5vw;
        .equipmentImage {
            width: 5vw;
        }
        .dataDiv {
            width: 80vw;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            align-items: center;
            div {
                width: 5vw;
            }
            .pFont {
                font-weight: bold;
                font-size: 1.3vw;
                text-align: center;
                line-height: 0;
            }
            .pFontData {
                font-weight: 400;
                font-size: 1vw;
                text-align: center;
                font-family: "Lato";
                color: black;
                line-height: 1.1vw;
                position: relative;
                top: 0.5vw;
            }
            .name {
                font-size: 1.1vw;
            }
            .status {
                background: #10b759;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                border-radius: 5px;
                width: 6.2vw;
                height: 2.2vw;
                display: flex;
                justify-content: center;
                align-items: center;
                .pFontStatus {
                    font-family: "Overpass";
                    font-style: normal;
                    font-weight: 600;
                    color: white;
                    line-height: 0;
                    margin-bottom: -0.4vw;
                    font-size: 1vw;
                }
            }
            .status1 {
                background: #fdb600;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                border-radius: 5px;
                width: 6.2vw;
                height: 2.2vw;
                display: flex;
                justify-content: center;
                align-items: center;
                .pFontStatus {
                    font-family: "Overpass";
                    font-style: normal;
                    font-weight: 600;
                    color: white;
                    line-height: 0;
                    margin-bottom: -0.4vw;
                    font-size: 1vw;
                }
            }
            .status2 {
                background: #b92309;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                border-radius: 5px;
                width: 6.2vw;
                height: 2.2vw;
                display: flex;
                justify-content: center;
                align-items: center;
                .pFontStatus {
                    font-family: "Overpass";
                    font-style: normal;
                    font-weight: 600;
                    color: white;
                    line-height: 0;
                    margin-bottom: -0.4vw;
                    font-size: 1vw;
                }
            }
            .status3 {
                background: #000000;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                border-radius: 5px;
                width: 6.2vw;
                height: 2.2vw;
                display: flex;
                justify-content: center;
                align-items: center;
                .pFontStatus {
                    font-family: "Overpass";
                    font-style: normal;
                    font-weight: 600;
                    color: white;
                    line-height: 0;
                    margin-bottom: -0.4vw;
                    font-size: 1vw;
                }
            }
        }
    }
    .optionsCustomeSelect {
        position: relative;
        top: 2vw;
        right: 8vw;
        background-color: #e6e6e6;
        max-height: 10vw;
        overflow-y: auto;
    }
    .equipInSelect {
        border: 1px solid #10b759;
        border-radius: 5px;
        box-shadow: $basicShadow;
        padding: 0.6vw;
        align-items: center;
        justify-content: center;
        margin: 5px;
        width: 40vw;
        height: 2.8vw;
        background-color: #ffffff;
        .equipmentImage {
            width: 3.2vw;
        }
        .dataDiv {
            width: 80%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            div {
                width: 1vw;
            }
            .pFontData {
                font-weight: 400;
                font-size: 0.7vw;
                text-align: center;
                font-family: "Lato";
                color: black;
                line-height: 1vw;
                width: 4vw;
                position: relative;
                top: 0.5vw;
            }
            .name {
                font-size: 0.65vw;
                width: 7vw;
            }
            .status {
                background: #10b759;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                border-radius: 5px;
                width: 6.2vw;
                height: 2.2vw;
                display: flex;
                justify-content: center;
                align-items: center;
                .pFontStatus {
                    font-family: "Overpass";
                    font-style: normal;
                    font-weight: 600;
                    color: white;
                    line-height: 0;
                    margin-bottom: -0.4vw;
                    font-size: 1vw;
                }
            }
            .status1 {
                background: #fdb600;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                border-radius: 5px;
                width: 6.2vw;
                height: 2.2vw;
                display: flex;
                justify-content: center;
                align-items: center;
                .pFontStatus {
                    font-family: "Overpass";
                    font-style: normal;
                    font-weight: 600;
                    color: white;
                    line-height: 0;
                    margin-bottom: -0.4vw;
                    font-size: 1vw;
                }
            }
            .status2 {
                background: #b92309;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                border-radius: 5px;
                width: 6.2vw;
                height: 2.2vw;
                display: flex;
                justify-content: center;
                align-items: center;
                .pFontStatus {
                    font-family: "Overpass";
                    font-style: normal;
                    font-weight: 600;
                    color: white;
                    line-height: 0;
                    margin-bottom: -0.4vw;
                    font-size: 1vw;
                }
            }
            .status3 {
                background: #000000;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                border-radius: 5px;
                width: 6.2vw;
                height: 2.2vw;
                display: flex;
                justify-content: center;
                align-items: center;
                .pFontStatus {
                    font-family: "Overpass";
                    font-style: normal;
                    font-weight: 600;
                    color: white;
                    line-height: 0;
                    margin-bottom: -0.4vw;
                    font-size: 1vw;
                }
            }
        }
    }
    .columnsNameFormTimeline {
        display: flex;
        align-self: flex-start;
        margin-left: 1vw;
        width: 78vw;
        justify-content: space-between;
        flex-direction: row;
        p {
            font-family: "Lato";
            font-style: normal;
            font-weight: 700;
            font-size: 1.2vw;
            line-height: 14px;
            color: #6a6a6a;
            text-align: center;
        }
    }
}

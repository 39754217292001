.public-container-layout {
	/* height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #dfe6e9; */
	background-color: white;
}

.login-container {
	/* display: flex;
	justify-content: center;
	align-items: center;
	height: 100%; */
}
.dailyDiv {
    padding: 1vw;
    padding-left: 2vw;
    .titleDiv {
        display: flex;
        justify-content: space-between;
        width: 22.7vw;
        .inventoryText {
            font-style: "Overpass";
            font-weight: 600;
            line-height: 26.88px;
            font-size: 1.7vw;
            color: #10b759;
        }
    }
    .buttonsSection {
        display: flex;
        justify-content: flex-end;
        .buttonUploPDF {
            background-color: #e65c00;
            color: white;
            font-weight: 600;
            font-family: "Overpass";
            border: 10px;
            border: 1px solid #ffffff;
            box-shadow: $basicShadow;
            height: 2.2vw;
            /* width: 7vw; */
            font-size: 1vw;
            margin-right: 1vw;
            border-radius: 8px;
        }
        .buttonUploPDF1 {
            background-color: #e65c00;
            color: white;
            font-weight: 600;
            font-family: "Overpass";
            border: 10px;
            border: 1px solid #ffffff;
            box-shadow: $basicShadow;
            height: 2.2vw;
            /* width: 9vw; */
            font-size: 1vw;
            margin-right: 1vw;
            border-radius: 8px;
        }
        .buttons {
            display: flex;
            justify-content: flex-end;
            .add {
                margin-right: 1vw;
            }
            .closeIcon {
                color: red;
                position: relative;
                right: 0.8vw;
                bottom: 0.8vw;
                font-size: 1.4vw;
            }
        }
        .Unselected {
            background-color: $green;
            color: white;
            font-weight: 600;
            font-family: "Overpass";
            border: 10px;
            border: 1px solid #ffffff;
            height: 2.2vw;
            /* width: 7vw; */
            font-size: 1vw;
            border-radius: 8px;
            box-shadow: $basicShadow;
            .plus {
                color: white;
            }
        }
        .noti{
            background-color: #e65c00;
            /* width: 11vw; */
            color: white;
            font-weight: 600;
            font-family: "Overpass";
            border: 10px;
            border: 1px solid #ffffff;
            height: 2.2vw;
            font-size: 1vw;
            border-radius: 8px;
            box-shadow: $basicShadow;
        }
        .Selected {
            background: $green;
            color: white;
            font-weight: 600;
            font-family: "Overpass";
            border: 10px;
            border: 1px solid #ffffff;
            box-shadow: inset $basicShadow;
            height: 2.2vw;
            /* width: 7vw; */
            font-size: 1vw;
            border-radius: 8px;
            .plus {
                color: white;
            }
        }
        /* .filters {
            margin-left: 10vw;
            display: flex;
            justify-content: space-around;
            width: 80vw;
            .label {
                color: black;
                font-weight: bold;
            }
            .select {
                background-color: #fdb600;
                width: 15vw;
                color: white;
                font-weight: bold;
                text-align: center;
                font-size: 1.2vw;
                .item {
                    color: white;
                }
            }
        } */
    }
    .body {
        display: flex;
        flex-direction: row;
        .form {
            width: 80vw;
            margin-top: 4vw;
        }
    }
    .divSearchEquips {
        .Selected {
            background: $green;
            color: white;
            font-weight: 600;
            font-family: "Overpass";
            border: 10px;
            border: 1px solid #ffffff;
            box-shadow: inset $basicShadow;
            height: 2.2vw;
            width: 10vw;
            font-size: 1vw;
            border-radius: 8px;
            .plus {
                color: white;
            }
        }
        .closeIcon {
            color: red;
            position: relative;
            right: 0.8vw;
            bottom: 1vw;
            font-size: 1.4vw;
        }
    }
    .selectsDependency {
        .select {
          width: 25vw;
          height: 3.6vw;
          margin-right: 2vw;
          border: 1px solid #c4c4c4;
          border-radius: 5px;
          font-size: 1.5vw;
        }
        .ant-select-selector {
          height: 3.4vw;
          border-radius: 5px;
          border: 0;
        }
      }
    .divcaseReportsSearched {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .titleItems {
            display: flex;
            justify-content: space-between;
            width: 75vw;
            margin-top: 1vw;
            p {
                font-family: "Lato";
                font-weight: 700;
                color: #6a6a6a;
                font-size: 1vw;
            }
        }
    }
    .div{
        display: flex;
        flex-wrap: wrap;
    }
    .showingText {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        margin-top: 2vw;
        margin-left: 0.5vw;
    }
    .divButtonsPages {
        display: flex;
        justify-content: center;
        margin-top: 1.5vw;
        width: 60vw;
        .sidesButtons {
            background-color: #f7f7f7;
            border: 2px solid #e9e9e9;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 1vw;
            padding-left: 1vw;
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 7vw;
            height: 3vw;
        }
        .RadioButtonGroup {
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 0;
            border-left: 0;
        }
        .radiobutton {
            width: 3vw;
            height: 3vw;
            color: #000;
            font-size: 1.2vw;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f7f7f7;
            border: 2px solid #e9e9e9;
        }
        .radiobutton:hover {
            color: #337ab7;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
        }
        .radiobuttonSelected {
            width: 3vw;
            height: 3vw;
            color: rgb(255, 255, 255);
            font-size: 1.2vw;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #337ab7;
            border: 2px solid #e9e9e9;
        }
        .radiobuttonSelected:hover {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
        }
    }
}
.ModalUploPDF .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
        color: $green;
        font-weight: 600;
        font-size: 2vw;
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .labelDiv {
        width: 100%;
        .label {
            color: $green;
            font-weight: 600;
            font-size: 1.3vw;
            margin-top: 0.4vw;
            margin-bottom: 0.1vw;
        }
    }
    .datepicker {
        width: 100%;
    }
    .subirarchivos {
        color: #000000a6;
        font-weight: 400;
        margin-top: 0.3vw;
        font-size: 1.3vw;
        width: 100%;
    }
    hr {
        width: 100%;
        margin-top: 0.3vw;
    }
    .buttons {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 2vw;
        .button {
            border: 1px solid #ffffff;
            box-shadow: $basicShadow;
            border-radius: 5px;
            color: white;
            width: 9vw;
            height: 3vw;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Overpass", sans-serif;
            font-weight: 600;
            font-size: 1.5vw;
            cursor: pointer;
        }
        .b2 {
            background: $green;
        }
        .b1 {
            background: $orange;
        }
    }
}

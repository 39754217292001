.FormServiceReportSystem {
  .errorMessage {
    color: red;
    font-size: 1vw;
  }
  .boton {
    width: 15vw;
    height: 3.5vw;
    font-size: 1.3vw;
    font-weight: bold;
  }
  .firstRep {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 3vw;
    margin-bottom: 3vw;
    .featuresText {
      border-bottom: 1px solid #e3dfdf;
      font-family: Montserrat;
      font-size: 1vw;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: left;
      color: #868686;
      margin-top: 2vw;
      padding-bottom: 0.3vw;
    }
    .div-equipment {
      display: flex;
      flex-wrap: wrap;
      width: 75vw;
      .buttonUnselect {
        width: 10vw;
        height: 3vw;
        font-size: 1.1vw;
        color: white;
        font-family: Overpass;
        font-style: normal;
        font-weight: bold;
        padding-bottom: 0.5vw;
        background-color: #fdb600;
        border: 1px solid #ffffff;
        box-shadow: 0px 4px 4px 0px #00000040;
        margin-top: 1vw;
      }
    }
    .labelDate,
    .label {
      color: #000000a6;
      font-family: Lato;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: left;
      font-size: 1vw;
      margin-bottom: 0.7vw;
    }
    .divInput {
      margin-top: 0;
    }
    .firstFields {
      display: flex;
      justify-content: space-between;
      width: 52vw;
      background-color: red;
      .label1 {
        margin-bottom: 0.5vw;
        font-weight: bold;
      }
      .select {
        width: 10vw;
        height: 2.5vw;
        margin-top: -0.05vw;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
      }
    }
    /* .divSearchEquips {
      .Selected {
        background: $green;
        color: white;
        font-weight: 600;
        font-family: "Overpass";
        border: 1px solid #ffffff;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        height: 2.2vw;
        font-size: 1vw;
        border-radius: 8px;
        min-width: 14vw;
        .plus {
          color: white;
        }
      }
      .closeIcon {
        color: red;
        position: relative;
        right: 0.8vw;
        bottom: 1vw;
        font-size: 1.4vw;
      }
    } */
    .dataFields {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5vw;
    }
    .dataFields1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1.5vw;
      .label1 {
        margin-bottom: -0.15vw;
        font-weight: bold;
      }
      .select {
        margin-right: 2.5vw;
        width: 11vw;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
      }
    }
    .titleSection {
      font-size: 1.7vw;
      color: #000000a6;
      font-family: Lato;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: left;
      font-size: 1vw;
      margin-bottom: 0.7vw;
    }
    .titleColumn {
      color: rgba(0, 0, 0, 0.6509803922);
      font-family: Lato;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: left;
      font-size: 1vw;
      margin-bottom: 0.7vw;
    }
    .divInputs {
      display: flex;
      align-items: center;
      .select .ant-select-selector {
        margin-top: 0.3vw;
      }
      input {
        font-family: Lato;
        font-weight: 700;
        text-align: left;
      }
    }
    .buttonAddComp {
      margin-bottom: 1.5vw;
      box-shadow: 0px 4px 4px 0px #00000040;
      background: #017c5b;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      width: 3vw;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0.3vw;
      padding-bottom: 0.3vw;
      cursor: pointer;
    }
    .softwareList {
      display: flex;
      margin-bottom: 2vw;
      width: 100%;
      flex-wrap: wrap;
      .software {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4vw;
        .softwareType {
          font-size: 1vw;
          color: #000000a6;
          font-weight: 700;
          position: relative;
          top: 0.65vw;
          text-align: left;
          width: 12vw;
        }
        .softwareInput {
          width: 8vw;
          height: 2.2vw;
          font-size: 1vw;
        }
        .softwareIcon {
          color: red;
          font-size: 1.8vw;
          margin-left: 0.5vw;
          cursor: pointer;
        }
      }
    }
    .inputPhoto {
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      width: 7vw;
      height: 2vw;
      margin-right: 0.7vw;
    }
    .radioG {
      display: flex;
      justify-content: space-between;
      margin-top: 1vw;
      .radio {
        font-family: Montserrat;
        font-size: 1vw;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        color: #868686;
      }
    }
    .descriptionDiv {
      .labelServiceReq {
        margin-bottom: -0.15vw;
        margin-top: 0.5vw;
        font-weight: bold;
      }
      .select {
        margin-right: 2.5vw;
        width: 23vw;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
      }
      .serviceDescription {
        font-weight: bold;
        margin-top: 2vw;
      }
      textarea {
        width: 75vw;
        height: 12.5vw;
        margin-bottom: 2.5vw;
        background: rgba(189, 189, 190, 0.05);
        border: 0.5px solid rgba(0, 0, 0, 0.08);
        box-sizing: border-box;
        border-radius: 3px;
      }
    }
  }
  .sparePartsDiv {
    display: flex;
    width: 75vw;
    margin-top: 1.5vw;
    justify-content: space-between;
    .closeIcon {
      color: red;
    }
  }
  .save {
    border-radius: 3px;
    border: 1px solid #fff;
    background: #017c5b;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 1vw;
    font-family: Overpass;
    font-weight: 600;
    line-height: 21px;
    padding: 0.2vw 0.6vw;
    align-self: "center";
    cursor: pointer;
  }
}
.CtmModalSystemReport {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .selectSoftware {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1.5vw;
    .itemSoftware {
      display: flex;
      justify-content: center;
      align-items: center;
      .name {
        color: white;
        font-size: 1.2vw;
        font-weight: bold;
        text-align: left;
        margin-right: 1.5vw;
        width: 6vw;
      }
      .select .ant-select-selector {
        width: 15vw;
        height: 2.5vw;
        /* line-height: 2.5vw; */
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        margin-bottom: 2vw;
      }
      .icon {
        color: white;
        font-size: 1.8vw;
        position: relative;
        bottom: 1.2vw;
        margin-left: 1vw;
        cursor: pointer;
      }
    }
  }
  .buttonSave {
    display: flex;
    width: 10vw;
    height: 3vw;
    align-self: center;
    background-color: #017c5b;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 2px solid #ffffff;
    margin-top: 2vw;
    margin-bottom: 1vw;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
}

.Timeline {
    padding: 1vw;
    padding-left: 2vw;
    .titleDiv {
        display: flex;
        justify-content: space-between;
        width: 22.7vw;
        .inventoryText {
            font-style: "Overpass";
            font-weight: 600;
            line-height: 26.88px;
            font-size: 1.7vw;
            color: #10b759;
        }
    }
    .selectsDependency {
        .select {
          width: 25vw;
          height: 3.6vw;
          margin-right: 2vw;
          border: 1px solid #c4c4c4;
          border-radius: 5px;
          font-size: 1.5vw;
        }
        .ant-select-selector {
          height: 3.4vw;
          border-radius: 5px;
          border: 0;
        }
      }
    .buttonsSection {
        display: flex;
        .buttons {
            width: 78vw;
            display: flex;
            justify-content: flex-end;
            .add {
                margin-right: 1vw;
            }
            .Unselected {
                background-color: $green;
                color: white;
                font-weight: 600;
                font-family: "Overpass";
                border: 10px;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                height: 2.2vw;
                /* width: 7vw; */
                font-size: 1vw;
                line-height: 0;
                border-radius: 8px;
                .plus {
                    color: white;
                    width: 1.1vw;
                }
            }
            .Selected {
                background: $green;
                color: white;
                font-weight: 600;
                font-family: "Overpass";
                border: 10px;
                border: 1px solid #ffffff;
                box-shadow: inset $basicShadow;
                height: 2.2vw;
                /* width: 7vw; */
                font-size: 1vw;
                line-height: 0;
                border-radius: 8px;
                .plus {
                    color: white;
                    width: 1.1vw;
                }
            }
            .prom {
                /* width: 9.5vw; */
                background-color: #e65c00;
                margin-right: 1vw;
            }
            .buttonUploPDF {
                background-color: $orange;
                color: white;
                font-weight: 600;
                font-family: "Overpass";
                border: 10px;
                border: 1px solid #ffffff;
                box-shadow: $basicShadow;
                height: 2.2vw;
                width: 8vw;
                font-size: 1vw;
                margin-right: 1vw;
                border-radius: 8px;
            }
            .closeIcon {
                color: red;
                position: relative;
                right: 0.8vw;
                bottom: 1vw;
                font-size: 1.4vw;
            }
        }
    }
}

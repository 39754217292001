.MPP {
  .divNoData {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    p {
      font-size: 2vw;
      font-weight: 600;
    }
  }
  .buttonsSection {
    display: flex;
    margin-top: 2vw;
    .buttons {
      width: 78vw;
      display: flex;
      justify-content: flex-end;
      .add {
        margin-right: 1vw;
      }
      .Unselected {
        background-color: $green;
        color: white;
        font-weight: 600;
        font-family: "Overpass";
        border: 10px;
        border: 1px solid #ffffff;
        box-shadow: $basicShadow;
        height: 2.2vw;
        /* width: 7vw; */
        font-size: 1vw;
        line-height: 0;
        border-radius: 8px;
        .plus {
          color: white;
        }
      }
      .Selected {
        background: $green;
        color: white;
        font-weight: 600;
        font-family: "Overpass";
        border: 10px;
        border: 1px solid #ffffff;
        box-shadow: inset $basicShadow;
        height: 2.2vw;
        /* width: 7vw; */
        font-size: 1vw;
        line-height: 0;
        border-radius: 8px;
        .plus {
          color: white;
        }
      }
      .closeIcon {
        color: red;
        position: relative;
        right: 0.8vw;
        bottom: 1vw;
        font-size: 1.4vw;
      }
    }
    .filters {
      margin-left: 10vw;
      display: flex;
      justify-content: space-around;
      width: 80vw;
      .label {
        color: black;
        font-weight: bold;
      }
      .select {
        background-color: #fdb600;
        width: 15vw;
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 1.2vw;
        .item {
          color: white;
        }
      }
    }
  }
  .body {
    display: flex;
    flex-direction: row;
    .form {
      width: 100%;
      /*  margin-top: 4vw; */
    }
  }
  .selectsDependency {
    .select {
      width: 25vw;
      height: 3.6vw;
      margin-right: 2vw;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      font-size: 1.5vw;
    }
    .ant-select-selector {
      height: 3.4vw;
      border-radius: 5px;
      border: 0;
    }
  }
  .div {
    display: flex;
    flex-direction: column;
    .EquipWithTimelines {
      display: flex;
      height: 30vw;
      margin-top: 2vh;
      .divTimelines {
        border: 1.5px solid #10b759;
        min-height: 100%;
        width: 62.3vw;
        align-self: flex-start;
        margin-left: 0.6vw;
        position: relative;
        padding-left: 1.4vw;
        padding-right: 1.4vw;
        top: 0.7vh;
        .columnsName {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          padding-top: 1vw;
          .pColumnTimeline {
            font-size: 1.2vw;
            font-weight: 600;
          }
        }
        hr {
          width: 97%;
        }
        .timeline {
          display: flex;
          justify-content: space-between;
          width: 100%;
          border-bottom: 1px #000 solid;
        }
      }
    }
  }

  .showingText {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    margin-top: 2vw;
    margin-left: 0.5vw;
  }
  .divButtonsPages {
    display: flex;
    justify-content: center;
    margin-top: 1.5vw;
    width: 60vw;
    .sidesButtons {
      background-color: #f7f7f7;
      border: 2px solid #e9e9e9;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 1vw;
      padding-left: 1vw;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 7vw;
      height: 3vw;
    }
    .RadioButtonGroup {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 0;
      border-left: 0;
    }
    .radiobutton {
      width: 3vw;
      height: 3vw;
      color: #000;
      font-size: 1.2vw;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f7f7f7;
      border: 2px solid #e9e9e9;
    }
    .radiobutton:hover {
      color: #337ab7;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
    }
    .radiobuttonSelected {
      width: 3vw;
      height: 3vw;
      color: rgb(255, 255, 255);
      font-size: 1.2vw;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #337ab7;
      border: 2px solid #e9e9e9;
    }
    .radiobuttonSelected:hover {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
    }
  }
}

.Summary {
    padding: 4vw;
    padding-left:1vw;
    .arrow {
        color: "#017c5b";
        margin-top: 1.5vw;
        font-size: 2vw;
    }
    .cards {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        .card {
            display: flex;
            width: 36vw;
            height: 25vw;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 4px 4px 0px #00000040;
            border-radius: 3px;
            margin-bottom: 1.8vw;
            cursor: pointer;
            p {
                font-size: 2.2vw;
                font-weight: 600;
                color: #ffffff;
                text-align: center;
                width: 18vw;
                z-index: 1;
                user-select: none;
            }
        }
        .green {
            width: 36vw;
            height: 25vw;
            position: absolute;
            background: linear-gradient(
                117.34deg,
                rgba(1, 124, 91, 0.3) 22.49%,
                rgba(85, 167, 145, 0.3) 112.97%,
                rgba(255, 255, 255, 0.3) 143.53%
            );
        }
        #card1 {
            background-image: url("../../../assets/images/createSummaryImage.png");
            background-size: 100% 100%;
        }
        #card2 {
            background-image: url("../../../assets/images/getSummaryImage.png");
            background-size: 100% 100%;
        }
        #card3 {
            background-image: url("../../../assets/images/generateInventoryImage.png");
            background-size: 100% 100%;
        }
        #card4 {
            background-image: url("../../../assets/images/generateReportImage.png");
            background-size: 100% 100%;
        }
    }
    .dataDiv {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .titleItems {
            display: flex;
            justify-content: space-between;
            width: 75vw;
            margin-top: 1vw;
            position: relative;
            left: 1.7vw;
            p {
                font-family: "Lato";
                font-weight: 700;
                color: #6a6a6a;
                font-size: 1vw;
            }
        }
        .showingText {
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            margin-top: 2vw;
            margin-left: 0.5vw;
        }
        .divButtonsPages {
            display: flex;
            justify-content: center;
            margin-top: 1.5vw;
            width: 60vw;
            .sidesButtons {
                background-color: #f7f7f7;
                border: 2px solid #e9e9e9;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: 1vw;
                padding-left: 1vw;
                cursor: pointer;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                width: 7vw;
                height: 3vw;
            }
            .RadioButtonGroup {
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 0;
                border-left: 0;
            }
            .radiobutton {
                width: 3vw;
                height: 3vw;
                color: #000;
                font-size: 1.2vw;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #f7f7f7;
                border: 2px solid #e9e9e9;
            }
            .radiobutton:hover {
                color: #337ab7;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                cursor: pointer;
            }
            .radiobuttonSelected {
                width: 3vw;
                height: 3vw;
                color: rgb(255, 255, 255);
                font-size: 1.2vw;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #337ab7;
                border: 2px solid #e9e9e9;
            }
            .radiobuttonSelected:hover {
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                cursor: pointer;
            }
        }
    }
}

.layout {
    /* height: 100vh;
    background-color: white; */
    .private-content-layout {
        display: flex;
        flex-direction: column;
        background-color: white;
        .buttonsLay {
            display: flex;
            align-self: center;
            justify-content: flex-start;
            align-items: flex-end;
            padding-left: 2vw;
            background-color: #017c5b;
            width: 98%;
            height: 7vw;
            box-shadow: 0px 4px 4px 0px #00000040;
            border-radius: 5px;
            z-index: 1;

            .button,
            .button1,
            .buttonNo {
                /* width: 9vw; */
                height: 2.5vw;
                font-size: 0.7vw;
                font-family: Overpass;
                font-style: normal;
                font-weight: 600;
                border: 1.5px solid #ffffff;
                border-radius: 5px;
                margin-right: 1vw;
            }

            .button {
                background-color: #3e5851;
            }

            .button1 {
                background-color: #fdb600;
            }

            .buttonNo {
                background-color: #2a3633;
                cursor: not-allowed;
            }
            .icon {
                width: 1.1vw;
                margin-right: 0.5vw;
            }
        }
    }

    .private-container-layout {
        background-color: white;
    }

    .layout {
        background-color: rgb(255, 255, 255);
    }
}
